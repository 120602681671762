import { FileType } from '../../../../models/Complaint';

interface ContentPreviewProps {
  cid: string;
  fileType: FileType;
}

export const ContentPreview = ({
  cid,
  fileType,
}: ContentPreviewProps): JSX.Element => {
  switch (fileType) {
    case FileType.Video:
      return (
        <video
          controls
          src={`https://${cid}.ipfs.w3s.link/`}
          // src={`https://ipfs.io/ipfs/${cid}/`}
          style={{ maxWidth: '100%' }}
        />
      );
    case FileType.Audio:
      return (
        <audio controls style={{ maxWidth: '100%' }}>
          <source src={`https://ipfs.io/ipfs/${cid}/`} type="audio/mpeg" />
        </audio>
      );
    case FileType.Text:
      return (
        <p>
          <iframe
            src={`https://ipfs.io/ipfs/${cid}/`}
            frameBorder="0"
            height="400"
            width="95%"
            title="content-of-text-file"
          />
        </p>
      );
    default:
      return (
        <img
          alt="cid-content"
          src={`https://ipfs.io/ipfs/${cid}/`}
          style={{ maxWidth: '100%' }}
        />
      );
  }
};
