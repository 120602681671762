import { createContext, Dispatch, useReducer } from 'react';

export enum UtilityContextActionTypes {
  SetSearchQueryObject = 'SET_SEARCH_QUERY_OBJECT',
}

export interface UtilityContextState {
  queryObject: {
    searchTerm: string;
    fromAnotherRoute: boolean;
  };
}

export interface ContextAction {
  type: UtilityContextActionTypes;
  value?: any;
}

const utilityContextReducer = (
  state: UtilityContextState,
  action: ContextAction
): UtilityContextState => {
  let newState = state;
  switch (action.type) {
    case UtilityContextActionTypes.SetSearchQueryObject:
      newState = {
        ...state,
        queryObject: action.value,
      };
      break;
  }

  return { ...newState };
};

const initialState = {
  queryObject: {
    searchTerm: '',
    fromAnotherRoute: false,
  },
};

const getInitialContext = (): UtilityContextState => {
  let context = initialState;

  return context;
};

// @ts-ignore
export const UtilityContext = createContext<{
  state: UtilityContextState;
  dispatch: Dispatch<ContextAction>;
}>({
  state: getInitialContext(),
  dispatch: () => null,
});

export const UtilityContextProvider = (props: any): JSX.Element => {
  const [state, dispatch] = useReducer(
    utilityContextReducer,
    getInitialContext()
  );

  return (
    <UtilityContext.Provider value={{ state: state, dispatch: dispatch }}>
      {props.children}
    </UtilityContext.Provider>
  );
};
