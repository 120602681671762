import { googleLogout } from '@react-oauth/google';
import { useEthers } from '@usedapp/core';
import { UserContext, UserContextActionTypes } from 'context/UserContext';
import { LoginType } from 'models/Account';
import { useContext } from 'react';
import { toast } from 'react-toastify';

export default function useLogout() {
  const userContext = useContext(UserContext);
  const { deactivate } = useEthers();

  const appLogOut = () => {
    toast.success('You have been logged out successfully!');
    userContext.dispatch({ type: UserContextActionTypes.LogOut });
  };

  const generalLogOut = (loginType: LoginType) => {
    if (loginType === LoginType.Wallet) {
      deactivate();
    } else {
      googleLogout();
    }
    appLogOut();
  };

  return generalLogOut;
}
