import { useEthers } from '@usedapp/core';
import { UserContext, UserContextActionTypes } from 'context/UserContext';
import { ApiService } from 'main/api/api.service';
import { AuthService } from 'main/api/auth.service';
import { LoginType } from 'models/Account';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as icons from 'resources/icons';
import DeleteAccountModal from './DeleteAccountModal/DeleteAccountModal';
import { bitscreenUri, lookingGlassUri } from '../../config';
import './SettingsPage.css';
import { useGoogleLogin } from '@react-oauth/google';
import useLogout from 'library/hooks/useLogout';
import { getAddressHash } from 'library/helpers/helper.functions';
import { useUpdateEffect } from 'usehooks-ts';
import JSZip from 'jszip';
import FileSaver from 'file-saver';

export const SettingsPage = (props: any): JSX.Element => {
  const [showDelete, setShowDelete] = useState(false);
  const userContext = useContext(UserContext);
  if (props.testing) {
    userContext.state.account = props.testing.state.account;
  }
  const {
    deactivate,
    activateBrowserWallet,
    account: connectedWalletAddress,
    library,
  } = useEthers();

  const [displayedWalletAddress, setDisplayedWalletAddress] = useState<{
    canDisplay: boolean;
    walletAddress: string;
  }>({
    canDisplay: false,
    walletAddress: '',
  });

  const apiService = ApiService();
  const authService = AuthService();
  const generalLogOut = useLogout();

  const logOut = () => {
    deactivate();
    toast.success('You have been logged out successfully!');
    userContext.dispatch({ type: UserContextActionTypes.LogOut });
  };

  useEffect(() => {
    if (!userContext.state.account || !userContext.state.loginType) return;

    authService
      .getAssessorWithProviderAccount(
        userContext.state.account.walletAddress ?? ''
      )
      .then((account) => {
        if (account) {
          userContext.dispatch({
            type: UserContextActionTypes.SetAccount,
            value: { ...userContext.state.account, ...account },
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateEffect(() => {
    if (
      !userContext.state.account ||
      !userContext.state.loginType ||
      displayedWalletAddress.canDisplay
    )
      return;

    if (userContext.state.loginType === LoginType.Wallet) {
      setDisplayedWalletAddress({
        canDisplay: true,
        walletAddress: userContext.state.account.walletAddress!,
      });
    } else if (
      userContext.state.account.walletAddressHashed &&
      connectedWalletAddress
    ) {
      setDisplayedWalletAddress({
        canDisplay:
          getAddressHash(connectedWalletAddress) ===
          userContext.state.account.walletAddressHashed,
        walletAddress: connectedWalletAddress,
      });
    }
  }, [userContext.state.account]);

  const closeDeleteModal = (success: boolean) => {
    setShowDelete(false);
    if (success) {
      deactivate();
      logOut();
    }
  };

  const linkGoogleToWalletAccount = async () => {
    activateBrowserWallet();
    const wallet = (connectedWalletAddress as string).toLowerCase();

    let { walletAddress, nonceMessage } =
      await apiService.generateNonceForSignature(wallet);

    const signedMessage = await library!
      .getSigner(walletAddress)
      .signMessage(nonceMessage as string);

    const updatedAssessor = await apiService.linkAssessorToWallet(
      wallet,
      signedMessage
    );

    userContext.dispatch({
      type: UserContextActionTypes.SetAccount,
      value: {
        ...userContext.state.account,
        ...updatedAssessor,
      },
    });
  };

  const unlinkFromSecondLoginType = async () => {
    try {
      const updatedAssessor = await apiService.unlinkSecondAccountType(
        userContext.state.loginType!
      );
      userContext.dispatch({
        type: UserContextActionTypes.SetAccount,
        value: {
          ...userContext.state.account,
          ...updatedAssessor,
        },
      });
    } catch (e: any) {
      if (e && e.data) {
        toast.error(e.data.message || e.data.error);
      } else {
        toast.error(
          'Could not unlink the account at the moment. Please try again later!'
        );
      }
    }
  };

  const linkWalletToGoogleAccount = async (tokenId: string) => {
    try {
      const updatedAssessor = await apiService.linkWalletToGoogleAccount(
        tokenId
      );
      userContext.dispatch({
        type: UserContextActionTypes.SetAccount,
        value: {
          ...userContext.state.account,
          ...updatedAssessor,
        },
      });
    } catch (e: any) {
      if (e && e.data) {
        toast.error(e.data.message || e.data.error);
      } else {
        toast.error(
          'Could not link the account at the moment. Please try again later!'
        );
      }
    }
  };

  const onGoogleLoginFailure = () => {
    return toast.error(
      'Could not authenticate you at the moment using Google authentication system. Please try again later!'
    );
  };

  const onGoogleLoginSuccess = async (tokenResponse: any) => {
    await linkWalletToGoogleAccount(tokenResponse.access_token);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: onGoogleLoginSuccess,
    onError: onGoogleLoginFailure,
    flow: 'implicit',
  });

  const exportAccountData = async () => {
    const response = await apiService.exportAccount();
    const zip = new JSZip();
    zip.file('account_data.json', JSON.stringify(response.accountData));

    if (response.not_published) {
      if (response.not_published.resolved) {
        for (const complaint in response.not_published.resolved) {
          zip.file(
            `reviewed_complaints/not_published/resolved/${complaint}`,
            JSON.stringify(response.not_published.resolved[complaint])
          );
        }
      }
      if (response.not_published.under_review) {
        for (const complaint in response.not_published.under_review) {
          zip.file(
            `reviewed_complaints/not_published/under_review/${complaint}`,
            JSON.stringify(response.not_published.under_review[complaint])
          );
        }
      }
    }

    if (response.published) {
      if (response.published.resolved) {
        for (const complaint in response.published.resolved) {
          zip.file(
            `reviewed_complaints/published/resolved/${complaint}`,
            JSON.stringify(response.published.resolved[complaint])
          );
        }
      }
      if (response.published.marked_as_spam) {
        for (const complaint in response.published.marked_as_spam) {
          zip.file(
            `reviewed_complaints/published/marked_as_spam/${complaint}`,
            JSON.stringify(response.published.marked_as_spam[complaint])
          );
        }
      }
    }

    zip.generateAsync({ type: 'blob' }).then(function (content) {
      FileSaver.saveAs(content, 'rodeo_export.zip');
    });
  };

  return (
    <>
      <DeleteAccountModal show={showDelete} handleClose={closeDeleteModal} />
      <div className="settings-page-header py-28 px-32 d-flex align-items-center">
        <div className="header-title d-flex align-items-center">
          <div
            aria-label="settings-page-title-div"
            className="title-text fs-24 lh-32 fw-700 text-primary-light mr-8"
          >
            Settings
          </div>
        </div>
      </div>

      <div className="settings-page-content px-16 py-24 d-flex">
        <div className="settings-page-left-section settings-page-account-details flex-3 mr-24">
          <div
            aria-label="settings-page-account-details-section-header"
            className="account-details-header py-12 px-16"
          >
            Account details
          </div>
          <div className="account-details-content p-16">
            <div className="account-details-wallet-actions pt-8 pb-24">
              {userContext.state.account?.loginEmail && (
                <>
                  <div className="wallet-label mb-8 fw-700 fs-14 lh-20 text-primary-light">
                    Google account connected
                  </div>
                  <div className="wallet-address mb-24 fw-500 fs-14 lh-20 text-primary-light">
                    Login email: {userContext.state.account?.loginEmail}
                  </div>
                </>
              )}
              {userContext.state.account?.walletAddressHashed && (
                <>
                  <div className="wallet-label mb-8 fw-700 fs-14 lh-20 text-primary-light">
                    Wallet connected
                  </div>
                  <div className="wallet-address mb-24 fw-500 fs-14 lh-20 text-primary-light">
                    Address:{' '}
                    {displayedWalletAddress.canDisplay
                      ? displayedWalletAddress.walletAddress
                      : '[Metamask account not active]'}
                  </div>
                </>
              )}
              <div className="account-details-actions d-flex justify-content-between">
                <div className="d-flex">
                  <div
                    aria-label="settings-page-log-out-button"
                    onClick={() =>
                      generalLogOut(userContext.state.loginType as LoginType)
                    }
                    className="settings-page-button account-details-log-out mr-12"
                  >
                    Log out
                  </div>
                  {(!userContext.state.account?.walletAddressHashed ||
                    !userContext.state.account?.loginEmail) && (
                    <div
                      aria-label="settings-page-log-out-button"
                      onClick={() => {
                        userContext.state.loginType === LoginType.Email
                          ? linkGoogleToWalletAccount()
                          : googleLogin();
                      }}
                      className="settings-page-button account-details-log-out"
                    >
                      Link{' '}
                      {userContext.state.loginType === LoginType.Email
                        ? 'wallet'
                        : 'Google'}{' '}
                      account
                    </div>
                  )}
                  {((userContext.state.loginType === LoginType.Wallet &&
                    userContext.state.account?.loginEmail) ||
                    (userContext.state.loginType === LoginType.Email &&
                      userContext.state.account?.walletAddressHashed)) && (
                    <div
                      aria-label="settings-page-log-out-button"
                      onClick={() => {
                        unlinkFromSecondLoginType();
                      }}
                      className="settings-page-button account-details-log-out"
                    >
                      Disconnect{' '}
                      {userContext.state.loginType === LoginType.Email
                        ? 'wallet'
                        : 'Google'}{' '}
                      account
                    </div>
                  )}
                </div>
                <div className="account-details-export-delete d-flex">
                  <div
                    className="settings-page-button account-details-export-account mr-12"
                    aria-label="settings-page-export-account-button"
                    onClick={exportAccountData}
                  >
                    Export account
                  </div>
                  <div
                    className="settings-page-button account-details-delete-account"
                    aria-label="settings-page-delete-account-button"
                    onClick={() => {
                      setShowDelete(true);
                    }}
                  >
                    Delete account
                  </div>
                </div>
              </div>
            </div>
            <div className="account-details-information py-24 d-flex">
              <div className="account-details-information-leftside d-flex flex-column flex-1">
                <div className="account-details-field-label">Business name</div>
                <div className="account-details-field-value">
                  {userContext.state.account?.provider.businessName}
                </div>
                <div className="account-details-field-label">
                  Contact person
                </div>
                <div className="account-details-field-value">
                  {userContext.state.account?.provider.contactPerson}
                </div>
                <div className="account-details-field-label">Address</div>
                <div className="account-details-field-value address-field-value">
                  {userContext.state.account?.provider.address}
                </div>
              </div>
              <div className="account-details-information-rightside d-flex flex-column flex-1">
                <div className="account-details-field-label">Website</div>
                <div className="account-details-field-value">
                  {userContext.state.account?.provider.website}
                </div>
                <div className="account-details-field-label">Email</div>
                <div className="account-details-field-value">
                  {userContext.state.account?.provider.email}
                </div>
                <div className="account-details-field-label">Country</div>
                <div className="account-details-field-value">
                  {userContext.state.account?.provider.country}
                </div>
              </div>
            </div>
          </div>
          <div className="account-details-footer d-flex flex-column align-items-start pt-8 pb-16 px-16">
            <div className="fs-14 lh-20 fw-400 mb-12">
              (Account details are managed in BitScreen)
            </div>
            <div
              aria-label="settings-page-edit-bitscreen-button"
              className="settings-page-button edit-in-bitscreen-button"
              onClick={() =>
                window.open(`${bitscreenUri()}/settings`, '_blank')
              }
            >
              Edit in BitScreen
            </div>
          </div>
        </div>
        <div className="settings-page-right-section flex-1">
          <div className="settings-page-app-connections mb-32">
            <div className="settings-page-app-connections-header py-12 px-16">
              App connections
            </div>
            <div className="settings-page-app-connections-content p-16">
              <div className="app-connections-bitscreen d-flex justify-content-between">
                <div className="fs-14 lh-20 fw-700 mb-8">BitScreen</div>
                <div className="d-flex h-100">
                  {(userContext.state.account?.rodeoConsentDate ||
                    (props.testing &&
                      props.testing.state.account.rodeoConsentDate)) && (
                    <img
                      alt="bitscreen-connected-tick"
                      aria-label="bitscreen-connected-tick"
                      src={icons.connected}
                    />
                  )}
                </div>
                {!userContext.state.account?.rodeoConsentDate &&
                  props.testing &&
                  !props.testing.state.account.rodeoConsentDate && (
                    <div className="app-connections-connect-button c-pointer no-text-select fs-14 lh-20 fw-500">
                      Connect
                    </div>
                  )}
              </div>
              <div className="app-connections-looking-glass d-flex justify-content-between">
                <div className="fs-14 lh-20 fw-700 ">Looking Glass</div>
                <a
                  className="app-connections-connect-button c-pointer no-text-select fs-14 lh-20 fw-500 external-link text-underline"
                  target="_blank"
                  href={`${lookingGlassUri()}/assessors/${
                    userContext.state.account?.id
                  }`}
                >
                  Launch
                </a>
              </div>
            </div>
          </div>
          <div className="settings-page-help">
            <div className="settings-page-help-header py-12 px-16">Help</div>
            <div className="settings-page-help-content p-16">
              <div
                onClick={() => {
                  window.open('https://google.com', '_blank');
                }}
                className="fw-500 fs-14 lh-20 mb-16 settings-page-help-link c-pointer"
              >
                Help link 1
              </div>
              <div
                onClick={() => {
                  window.open('https://google.com', '_blank');
                }}
                className="fw-500 fs-14 lh-20 mb-16 settings-page-help-link c-pointer"
              >
                Help link 2
              </div>
              <div
                onClick={() => {
                  window.open('https://google.com', '_blank');
                }}
                className="fw-500 fs-14 lh-20 settings-page-help-link c-pointer"
              >
                Help link 3
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        aria-label="showDelete-test-div"
        className="showDelete-test-div display-none"
      >
        {showDelete + '-test'}
      </div>
    </>
  );
};
