import { ComplaintReview } from 'modules/ComplaintReview/ComplaintReview';
import Dashboard from 'modules/Dashboard';
import { SettingsPage } from 'modules/SettingsPage/SettingsPage';

export const routes = [
  {
    path: '/',
    element: <Dashboard />,
  },
  {
    path: '/settings',
    element: <SettingsPage />,
  },
  {
    path: '/complaint/:_id',
    element: <ComplaintReview />,
  },
];
