import {
  ComplainantType,
  ComplaintStatus,
  ComplaintType,
  OnBehalfOf,
} from 'models/Complaint';

const ComplaintService = {
  renderType: (violation: ComplaintType): string => {
    switch (violation) {
      case ComplaintType.Copyright:
        return 'Copyright Infringement';

      case ComplaintType.Inappropriate:
        return 'Inappropriate Content';
      case ComplaintType.Other:
        return 'Other';
      default:
        return 'General violation';
    }
  },
  renderComplainantType: (violation: ComplainantType): string => {
    switch (violation) {
      case ComplainantType.Government:
        return 'Government';
      case ComplainantType.Organization:
        return 'Organization';
      case ComplainantType.Individual:
        return 'Individual';
      default:
        return 'None';
    }
  },
  renderOnBehalfOf: (onBehalfOf: OnBehalfOf): string => {
    switch (onBehalfOf) {
      case OnBehalfOf.Self:
        return 'Self';
      case OnBehalfOf.OtherParty:
        return 'Other Party';
      default:
        return 'None';
    }
  },
  renderStatus: (
    status: ComplaintStatus
  ): {
    badgeText: string;
    badgeCssClass: string;
    badgeLetter: string;
  } => {
    let badgeText = '';
    let badgeCssClass = '';
    let badgeLetter = '';
    switch (status) {
      case ComplaintStatus.New:
        badgeText = 'New';
        badgeCssClass = 'created';
        badgeLetter = 'N';
        break;

      case ComplaintStatus.UnderReview:
        badgeText = 'Under review';
        badgeCssClass = 'under-review';
        badgeLetter = 'U';
        break;

      case ComplaintStatus.Resolved:
        badgeText = 'Resolved';
        badgeCssClass = 'finalized';
        badgeLetter = 'R';
        break;

      case ComplaintStatus.Spam:
        badgeText = 'Spam';
        badgeCssClass = 'spam';
        badgeLetter = 'S';
        break;

      default:
        badgeText = 'N/A';
        badgeCssClass = 'spam';
        badgeLetter = 'N/A';
        break;
    }

    return {
      badgeText,
      badgeCssClass,
      badgeLetter,
    };
  },
};

export default ComplaintService;
