import * as icons from 'resources/icons';
import '../CidRow/CidRow.css';
import { ContentModalObject } from '../ComplaintCids';

interface FileCidRowProps {
  openContentModal: (newContentModal: Partial<ContentModalObject>) => void;
  fileCid: string;
  infringementIndex: number;
  fileIndex: number;
}

export const FileCidRow = (props: FileCidRowProps): JSX.Element => (
  <div className="cid-row-container">
    <div className="py-8 px-16 d-flex align-items-center">
      <div
        className="cid-row-text-actions d-grid"
        style={{ gridTemplateColumns: '10fr 1fr 1fr' }}
      >
        <div className="cid-row-text too-long-text ml-20 mr-10">
          {props.fileCid}
        </div>
        <div className="cid-row-actions d-flex align-items-center mr-24">
          <img
            alt="search-icon"
            className="mr-10 c-pointer no-text-select"
            src={icons.searchGrey}
            onClick={() => {
              props.openContentModal({
                infringementIndex: props.infringementIndex,
                fileIndex: props.fileIndex,
              });
            }}
          />
          <img
            alt="check-content-in-new-tab-icon"
            className="c-pointer no-text-select"
            src={icons.external}
            onClick={() =>
              window.open(`https://${props.fileCid}.ipfs.w3s.link/`, '_blank')
            }
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div style={{ width: '76px' }}></div>
      </div>
    </div>
  </div>
);
