import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import './SubmitTicketModal.css';

interface SubmitTicketModalProps {
  show: boolean;
  closeCallback: (canSubmit: boolean, doNotShowMessageAgain: boolean) => void;
  filterListsSelected: boolean;
}

export default function SubmitTicketModal(
  props: SubmitTicketModalProps
): JSX.Element {
  const [doNotShowMessageAgain, setDoNotShowMessageAgain] = useState(false);

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.closeCallback(false, false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Submit ticket</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.filterListsSelected ? (
          <div>
            <div className="submit-ticket-modal-text mb-12">
              This action will close the ticket, add accepted CIDs to the
              selected filter list, publish a record to the transparency hub and
              notify the complainant via email.
            </div>
          </div>
        ) : (
          <div>
            <div className="submit-ticket-modal-text mb-12">
              No filter list has been selected.
            </div>
            <div className="submit-ticket-modal-text">
              Do you want to proceed anyway?
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {props.filterListsSelected ? (
          <div className="submit-ticket-modal-footer d-flex justify-content-between align-items-center">
            <div
              onClick={() => setDoNotShowMessageAgain(!doNotShowMessageAgain)}
              className="do-not-show-checkbox no-text-select"
            >
              <Form.Group>
                <Form.Check
                  className="c-pointer"
                  checked={doNotShowMessageAgain}
                  type="checkbox"
                  label="Don't show this message again"
                />
              </Form.Group>
            </div>
            <div className="submit-ticket-footer-actions d-flex">
              <div
                onClick={() => props.closeCallback(false, false)}
                className="submit-ticket-cancel-button text-tertiary-light py-8 px-12 c-pointer no-text-select mr-12"
              >
                Cancel
              </div>
              <div
                className="submit-ticket-submit-button text-primary-dark py-8 px-12 c-pointer no-text-select"
                onClick={() => props.closeCallback(true, doNotShowMessageAgain)}
              >
                Submit
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex">
            <div
              onClick={() => props.closeCallback(false, false)}
              className="submit-ticket-cancel-button text-tertiary-light py-8 px-20 c-pointer no-text-select mr-12"
            >
              No
            </div>
            <div
              className="submit-ticket-submit-button text-primary-dark py-8 px-18 c-pointer no-text-select"
              onClick={() => props.closeCallback(true, false)}
            >
              Yes
            </div>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}
