import { Visibility } from 'models/FilterList';
import React, { useState } from 'react';
import { Dropdown, Form, Modal } from 'react-bootstrap';
import './BitScreenListModal.css';

export interface BitScreenListModalProps {
  show: boolean;
  closeCallback: (closeObject: {
    name: string;
    description: string;
    listType: Visibility | null;
    shouldCreate: boolean;
  }) => void;
}

export default function BitScreenListModal(
  props: BitScreenListModalProps
): JSX.Element {
  const [formValues, setFormValues] = useState<{
    name: string;
    description: string;
    listType: Visibility | null;
  }>({
    name: '',
    description: '',
    listType: null,
  });

  const visibilityOptions = [
    Visibility.Private,
    Visibility.Public,
    Visibility.Shared,
  ];

  const changeInputValue = (e: any, inputType: string) => {
    setFormValues({
      ...formValues,
      [inputType]: inputType !== 'listType' ? e.target.value : e,
    });
  };

  const resetAndCloseForm = (shouldCreate: boolean) => {
    const closeObject = {
      ...formValues,
      shouldCreate,
    };

    setFormValues({
      name: '',
      description: '',
      listType: null,
    });

    props.closeCallback(closeObject);
  };

  const renderVisibility = (visibility: Visibility) => {
    switch (visibility) {
      case Visibility.Private:
        return 'Private';

      case Visibility.Public:
        return 'Public';

      case Visibility.Shared:
        return 'Shared';

      case Visibility.Exception:
        return 'Exception';
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        resetAndCloseForm(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create new list in BitScreen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="label-input-group name-group">
          <div className="form-control-label fs-14 lh-20 fw-700 mb-8">Name</div>
          <div className="form-control-input mb-24">
            <Form.Control
              type="text"
              placeholder="Name"
              value={formValues.name}
              onChange={(e) => changeInputValue(e, 'name')}
            />
          </div>
        </div>
        <div className="label-input-group description-group">
          <div className="form-control-label fs-14 lh-20 fw-700 mb-8">
            Description
          </div>
          <div className="form-control-input mb-24">
            <Form.Control
              type="text"
              placeholder="Description"
              value={formValues.description}
              onChange={(e) => changeInputValue(e, 'description')}
            />
          </div>
        </div>
        <div className="label-input-group list-type-group">
          <div className="form-control-label fs-14 lh-20 fw-700 mb-8">
            List type
          </div>
          <div className="form-control-input">
            <Dropdown>
              <Dropdown.Toggle
                className={`toggle-complaint-actions list-select-toggle fw-400 text-primary-light fs-14 lh-20`}
              >
                {formValues.listType
                  ? renderVisibility(formValues.listType)
                  : 'Select list type'}
              </Dropdown.Toggle>

              <Dropdown.Menu className="toggle-menu">
                {visibilityOptions.map((e, index) => (
                  <Dropdown.Item
                    onClick={() => changeInputValue(e, 'listType')}
                    key={`bitscreen-list-item-${index}`}
                  >
                    {renderVisibility(e)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          onClick={() => resetAndCloseForm(false)}
          className="bitscreen-list-cancel-button text-tertiary-light py-8 px-12 c-pointer no-text-select"
        >
          Cancel
        </div>
        <div
          onClick={() => resetAndCloseForm(true)}
          className="bitscreen-list-create-button text-primary-dark py-8 px-12 c-pointer no-text-select"
        >
          Create list
        </div>
      </Modal.Footer>
    </Modal>
  );
}
