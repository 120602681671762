import { Infringement } from 'models/Infringement';
import { Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import * as icons from 'resources/icons';
import './CidRow.css';
import { ContentModalObject } from '../ComplaintCids';

export enum RowType {
  Folder = 'folder',
  File = 'file',
}

interface CidRowProps {
  infringement: Infringement;
  checkList: boolean[];
  index: number;
  toggleCheck: (index: number) => void;
  setInfringement: (index: number, infringement: Infringement) => void;
  canInteract: boolean;
  isSpam: boolean;
  openContentModal: (newContentModal: Partial<ContentModalObject>) => void;
  rowType: RowType;
}

export const CidRow = (props: CidRowProps): JSX.Element => {
  return (
    <div className="cid-row-container">
      <div className="py-8 px-16 d-flex align-items-center">
        {!props.isSpam && props.canInteract && props.checkList[props.index] && (
          <Form.Check
            type="checkbox"
            className="mr-12"
            id={`check-${props.infringement.value}`}
            checked={props.checkList[props.index]}
            onChange={() => props.toggleCheck(props.index)}
          />
        )}
        <div className="cid-row-text-actions">
          <div className="cid-row-text too-long-text mr-10">
            {props.infringement.value} ({props.infringement.fileType})
          </div>
          <div className="cid-row-actions-accept-reject">
            {!(props.rowType === RowType.Folder) ? (
              <div className="cid-row-actions d-flex align-items-center mr-24">
                <img
                  alt="search-icon"
                  className="mr-10 c-pointer no-text-select"
                  src={icons.searchGrey}
                  onClick={() => {
                    props.openContentModal({
                      infringementIndex: props.index,
                    });
                  }}
                />
                {!props.isSpam && props.canInteract && (
                  <img
                    alt="comment-icon"
                    onClick={() => {
                      if (typeof props.infringement.reason === 'string') {
                        props.setInfringement(props.index, {
                          ...props.infringement,
                          reason: undefined,
                        });
                      } else {
                        props.setInfringement(props.index, {
                          ...props.infringement,
                          reason: '',
                        });
                      }
                    }}
                    className="mr-12 c-pointer no-text-select"
                    src={
                      typeof props.infringement.reason === 'string'
                        ? icons.messageBlue
                        : icons.messageGrey
                    }
                  />
                )}

                <img
                  alt="check-content-in-new-tab-icon"
                  className="c-pointer no-text-select"
                  src={icons.external}
                  onClick={() =>
                    window.open(
                      `https://${
                        props.infringement.fileCids as string
                      }.ipfs.w3s.link/`,
                      '_blank'
                    )
                  }
                  style={{ cursor: 'pointer' }}
                />
              </div>
            ) : (
              <div style={{ width: '92px' }}></div>
            )}
            <div className="cid-row-accept-reject">
              {!props.isSpam &&
                (props.canInteract ? (
                  <ToggleButtonGroup
                    type="radio"
                    name={`accepted-${props.infringement._id}`}
                    value={props.infringement.accepted ? 1 : 0}
                    onChange={(e) => {
                      props.setInfringement(props.index, {
                        ...props.infringement,
                        accepted: e === 1 ? true : false,
                      });
                    }}
                  >
                    <ToggleButton
                      className={`${
                        props.infringement.accepted
                          ? 'white-background'
                          : 'blue-background'
                      }`}
                      id={`radio-decline-${props.infringement._id}`}
                      value={0}
                    >
                      <img
                        alt="reject-cid-icon"
                        src={
                          props.infringement.accepted
                            ? icons.blueX
                            : icons.whiteX
                        }
                      />
                    </ToggleButton>

                    <ToggleButton
                      className={
                        !props.infringement.accepted
                          ? 'white-background'
                          : 'blue-background'
                      }
                      id={`radio-accept-${props.infringement._id}`}
                      value={1}
                    >
                      <img
                        alt="accept-cid-icon"
                        src={
                          !props.infringement.accepted
                            ? icons.blueTick
                            : icons.whiteTick
                        }
                      />
                    </ToggleButton>
                  </ToggleButtonGroup>
                ) : (
                  <div className="resolved-cid-row-accept-reject fs-14 lh-20 fw-500">
                    {props.infringement.accepted ? (
                      <span className="resolved-cid-row-accepted">
                        Accepted
                      </span>
                    ) : (
                      <div className="resolved-cid-row-rejected">Rejected</div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      {!props.isSpam && typeof props.infringement.reason === 'string' && (
        <div>
          {props.canInteract && (
            <div className="mt-8 pb-12 pr-16 pl-20">
              <div className="ml-24 text-primary-light fs-14 lh-20 fw-700 mb-8">
                Add a reason <span className="fw-400">(optional, public)</span>
              </div>
              <div className="ml-24">
                <Form.Control
                  rows={3}
                  as="textarea"
                  placeholder="Reason"
                  value={props.infringement.reason || ''}
                  onChange={(e) => {
                    props.setInfringement(props.index, {
                      ...props.infringement,
                      reason: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          )}
          {!props.canInteract && props.infringement.reason && (
            <div className="mt-8 pb-12 pr-16 pl-16 fs-14 lh-20 text-secondary-light">
              {props.infringement.reason}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
