import { useGoogleLogin } from '@react-oauth/google';
import { useEthers } from '@usedapp/core';
import { UserContext, UserContextActionTypes } from 'context/UserContext';
import { ApiService } from 'main/api/api.service';
import { AuthService } from 'main/api/auth.service';
import {
  Account,
  BasicAuthInfoEmail,
  BasicAuthInfoWallet,
  LoginType,
} from 'models/Account';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import * as icons from 'resources/icons';
import { useUpdateEffect } from 'usehooks-ts';
import ConsentModal from './ConsentModal/ConsentModal';
import './Login.css';

export const Login = (): JSX.Element => {
  const userContext = useContext(UserContext);
  const {
    activateBrowserWallet,
    account: connectedWalletAddress,
    library,
  } = useEthers();
  const [tosAgreementStatus, setTosAgreementStatus] = useState({
    isAgreed: false,
    shouldShowTosModal: false,
  });
  const [loginProcessConfig, setLoginProcessConfig] = useState<{
    isInitiated: boolean;
    loginType: LoginType | null;
    googleTokenId: string | null;
  }>({
    isInitiated: false,
    loginType: null,
    googleTokenId: null,
  });
  const authService = AuthService();
  const apiService = ApiService();

  const setAccountAfterAuth = async (assessor: Account) => {
    setLoginProcessConfig({
      isInitiated: false,
      loginType: null,
      googleTokenId: null,
    });
    userContext.dispatch({
      type: UserContextActionTypes.SetAccount,
      value: assessor,
    });
    const config = await apiService.getConfig();
    userContext.dispatch({
      type: UserContextActionTypes.SetConfig,
      value: config,
    });
    userContext.dispatch({
      type: UserContextActionTypes.SetLoginType,
      value: loginProcessConfig.loginType,
    });
  };

  const updateAssessorWithConsentDate = async () => {
    await authService.updateAssessor({
      rodeoConsentDate: new Date().toISOString(),
    });
  };

  const createAndAuthAssessorWallet = async (
    assessor: BasicAuthInfoWallet | null,
    walletAddress: string
  ) => {
    let nonceMessage = assessor
      ? assessor.nonceMessage
      : (await authService.createAssessor(walletAddress)).nonceMessage;

    const signedMessage = await library!
      .getSigner(walletAddress)
      .signMessage(nonceMessage);

    const authenticatedAssessor = await authService.authenticateAssessor(
      walletAddress,
      signedMessage
    );

    await setAccountAfterAuth(authenticatedAssessor);

    return assessor;
  };

  const authenticateByWallet = async () => {
    const walletAddress = connectedWalletAddress as string;
    try {
      let assessor = await authService.getAuthInfoWallet(walletAddress);

      if (!assessor || !assessor.rodeoConsentDate) {
        return setTosAgreementStatus({
          shouldShowTosModal: true,
          isAgreed: false,
        });
      }

      await createAndAuthAssessorWallet(assessor, walletAddress);
    } catch (e: any) {
      if (e && (e.error || e.message)) {
        toast.error(e.error || e.message);
      } else {
        toast.error('Error while trying to sign in with Metamask!');
      }
      setLoginProcessConfig({
        isInitiated: false,
        loginType: null,
        googleTokenId: null,
      });
    }
  };

  const authenticateByWalletAfterConsent = async () => {
    const walletAddress = connectedWalletAddress as string;
    try {
      let assessor = await authService.getAuthInfoWallet(walletAddress);

      assessor = await createAndAuthAssessorWallet(assessor, walletAddress);

      await updateAssessorWithConsentDate();
    } catch (e) {
      toast.error('Error while trying to sign in with Metamask!');
      setLoginProcessConfig({
        isInitiated: false,
        loginType: null,
        googleTokenId: null,
      });
    }
  };

  const createAndAuthAssessorEmail = async (
    assessor: BasicAuthInfoEmail | null
  ) => {
    const googleTokenId = loginProcessConfig.googleTokenId as string;

    if (!assessor) {
      assessor = await authService.createAssessorByEmail(googleTokenId);
    }

    const assessorAccount = await authService.authenticateAssessorByEmail(
      googleTokenId
    );

    await setAccountAfterAuth(assessorAccount);

    return assessor;
  };

  const authenticateByEmail = async () => {
    try {
      const googleTokenId = loginProcessConfig.googleTokenId as string;
      let assessor = await authService.getAuthInfoEmail(googleTokenId);

      if (!assessor || !assessor.rodeoConsentDate) {
        return setTosAgreementStatus({
          shouldShowTosModal: true,
          isAgreed: false,
        });
      }

      await createAndAuthAssessorEmail(assessor);
    } catch (e) {
      toast.error('Error while trying to sign in with Google Account!');
      setLoginProcessConfig({
        isInitiated: false,
        loginType: null,
        googleTokenId: null,
      });
    }
  };

  const authenticateByEmailAfterConsent = async () => {
    try {
      const googleTokenId = loginProcessConfig.googleTokenId as string;
      let assessor = await authService.getAuthInfoEmail(googleTokenId);

      assessor = await createAndAuthAssessorEmail(assessor);

      await updateAssessorWithConsentDate();
    } catch (e) {
      toast.error('Error while trying to sign in with Google Account!');
      setLoginProcessConfig({
        isInitiated: false,
        loginType: null,
        googleTokenId: null,
      });
    }
  };

  useUpdateEffect(() => {
    if (tosAgreementStatus.shouldShowTosModal) return;

    if (!tosAgreementStatus.isAgreed) {
      setLoginProcessConfig({
        isInitiated: false,
        loginType: null,
        googleTokenId: null,
      });
    } else {
      if (loginProcessConfig.loginType === LoginType.Wallet) {
        authenticateByWalletAfterConsent();
      } else if (loginProcessConfig.loginType === LoginType.Email) {
        authenticateByEmailAfterConsent();
      }
    }
  }, [tosAgreementStatus.shouldShowTosModal]);

  useUpdateEffect(() => {
    if (loginProcessConfig.isInitiated) {
      if (loginProcessConfig.loginType === LoginType.Wallet) {
        authenticateByWallet();
      } else if (loginProcessConfig.loginType === LoginType.Email) {
        authenticateByEmail();
      }
    }
  }, [loginProcessConfig.isInitiated]);

  const loginAction = async () => {
    await activateBrowserWallet();
    setLoginProcessConfig({
      isInitiated: true,
      loginType: LoginType.Wallet,
      googleTokenId: null,
    });
  };

  const onGoogleLoginFailure = () => {
    return toast.error(
      'Could not authenticate you at the moment using Google authentication system. Please try again later!'
    );
  };

  const onGoogleLoginSuccess = async (tokenResponse: any) => {
    setLoginProcessConfig({
      isInitiated: true,
      loginType: LoginType.Email,
      googleTokenId: tokenResponse.access_token,
    });
  };

  const googleLogin = useGoogleLogin({
    onError: onGoogleLoginFailure,
    onSuccess: (tokenResponse) => onGoogleLoginSuccess(tokenResponse),
    flow: 'implicit',
  });

  return (
    <div className="login-page-container d-flex flex-column justify-content-center align-items-center">
      <div
        aria-label="isLoginInitialized-test-div"
        onClick={() => {
          toast.success('Successfully logged in!');
          setLoginProcessConfig({
            isInitiated: true,
            loginType: LoginType.Wallet,
            googleTokenId: null,
          });
        }}
        className="isLoginInitialized-test-div display-none"
      >
        {loginProcessConfig + '-test'}
      </div>
      <div
        aria-label="login-page-title"
        className="d-flex justify-content-center align-items-center login-page-title text-primary-light fs-32 lh-40 fw-700 mt-32 pt-32"
      >
        Welcome to Rodeo
      </div>
      <div
        aria-label="login-page-sub-title"
        className="d-flex justify-content-center align-items-center login-page-sub-title text-primary-light fs-16 lh-24 fw-400 mt-12"
      >
        Sign in with your email, your Google account or your Metamask wallet.
      </div>
      <div
        aria-label="login-page-metamask"
        onClick={loginAction}
        className="d-flex justify-content-center align-items-center login-page-option-container mt-32 c-pointer"
      >
        <div className="connection-option py-16 px-16">
          <div className="connection-icon-container">
            <img width={35} src={icons.metamask} alt="metamask-icon" />{' '}
          </div>
          <span className="fs-16 lh-24 fw-700 ml-12">
            Sign in with Metamask
          </span>
        </div>
      </div>
      <div
        aria-label="login-page-google"
        onClick={() => googleLogin()}
        className="d-flex justify-content-center align-items-center login-page-option-container mt-16 c-pointer"
      >
        <div className="connection-option py-16 px-16">
          <div className="connection-icon-container">
            <img width={30} src={icons.googleIcon} alt="metamask-icon" />{' '}
          </div>
          <span className="fs-16 lh-24 fw-700 ml-12">Sign in with Google</span>
        </div>
      </div>
      <div
        aria-label="login-page-terms-privacy"
        className="d-flex justify-content-center align-items-center login-page-terms-privacy fs-14 lh-20 fw-400 mt-32"
      >
        By using Rodeo, you agree to our{' '}
        <span
          aria-label="terms"
          className="text-underline mx-1 cursor-pointer"
          onClick={() => {
            window.open('https://google.com', '_blank');
          }}
        >
          Terms
        </span>{' '}
        &{' '}
        <span
          aria-label="privacy-policy"
          className="text-underline mx-1 cursor-pointer"
          onClick={() => {
            window.open('https://google.com', '_blank');
          }}
        >
          Privacy Policy
        </span>
        .
      </div>
      <ConsentModal
        show={tosAgreementStatus.shouldShowTosModal}
        closeCallback={(consentValue: boolean) => {
          setTosAgreementStatus({
            shouldShowTosModal: false,
            isAgreed: consentValue,
          });
        }}
      />
    </div>
  );
};
