import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { UserContext } from 'context/UserContext';
import { ApiService } from 'main/api/api.service';
import { LoginType } from 'models/Account';
import React, { useContext, useState } from 'react';
import {
  Alert,
  Button,
  Form,
  FormControl,
  InputGroup,
  Row,
} from 'react-bootstrap';

interface DeleteAccountModalProps {
  show: boolean;
  handleClose: (result: boolean) => void;
  testing?: boolean;
}

const DeleteAccountModal = ({
  show,
  handleClose,
  testing = false,
}: DeleteAccountModalProps): JSX.Element => {
  const apiService = ApiService();

  const context = useContext(UserContext);
  const [confirmText, setConfirmText] = useState<string>('');
  const hasUsedFilters = false;
  const account = context.state.account;
  const loginType = context.state.loginType as LoginType;

  const isConfirmDeleteDisabled = () => {
    if (testing) return false;
    if (
      loginType === LoginType.Wallet &&
      confirmText.toLowerCase() !== account?.walletAddress?.slice(-4)
    )
      return true;
  };

  const confirmDelete = () => {
    if (testing) {
      return handleClose(true);
    }
    if (
      loginType === LoginType.Email ||
      (loginType === LoginType.Wallet &&
        confirmText.toLowerCase() === account?.walletAddress?.slice(-4))
    ) {
      apiService.deleteProvider().then(
        (response) => {
          setConfirmText('');
          handleClose(response.success);
        },
        (e) => {
          if (e && e.status === 401) {
            return;
          }
        }
      );
    }
  };

  return (
    <>
      <Dialog
        open={show}
        sx={{
          '& .MuiPaper-root': { maxWidth: '800px' },
        }}
        onClose={() => handleClose(false)}
      >
        <DialogTitle aria-label="delete-modal-page-title-div">
          Delete account
        </DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          <Form>
            <Row>
              <Alert
                aria-label="delete-modal-warning-div"
                style={{ marginRight: 10 }}
                variant="danger"
              >
                This action is irreversible. If you did not export your account
                data prior to deleting, you will not be able to recover your
                data.
              </Alert>
              {hasUsedFilters && (
                <Alert style={{ marginRight: 10 }} variant="danger">
                  Some of your lists are currently in use by other subscribers.
                  Deleting your account will also remove the lists from their
                  accounts.
                </Alert>
              )}
              {loginType === LoginType.Wallet && (
                <InputGroup className="input-group-prepend no-wrap flex mb-2">
                  <InputGroup.Text>{account?.walletAddress}</InputGroup.Text>
                  <FormControl
                    type="text"
                    className="w-100"
                    aria-label="delete-modal-wallet-input"
                    placeholder="Enter the last 4 characters of your wallet"
                    onChange={(ev) => setConfirmText(ev.target.value)}
                  />
                </InputGroup>
              )}
              {loginType === LoginType.Wallet && (
                <Form.Text
                  aria-label="delete-modal-explanation-div"
                  className="text-muted"
                >
                  To confirm that you want to delete this account, please input
                  the last 4 characters of your wallet address.
                </Form.Text>
              )}
            </Row>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button
            aria-label="delete-modal-cancel-button"
            color="primary"
            title="Cancel"
            disabled={false}
            onClick={() => handleClose(false)}
          >
            Cancel
          </Button>
          <Button
            aria-label="delete-modal-delete-button"
            variant="danger"
            onClick={confirmDelete}
            disabled={isConfirmDeleteDisabled()}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteAccountModal;
