import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import './MarkSpamModal.css';

interface MarkSpamModalProps {
  show: boolean;
  closeCallback: (canMarkSpam: boolean, doNotShowMessageAgain: boolean) => void;
  numberOfTickets: number;
}

export default function MarkSpamModal(props: MarkSpamModalProps): JSX.Element {
  const [doNotShowMessageAgain, setDoNotShowMessageAgain] = useState(false);

  const isMultiple = props.numberOfTickets > 1;

  return (
    <Modal
      className="mark-spam-modal"
      show={props.show}
      onHide={() => {
        props.closeCallback(false, false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isMultiple ? 'Mark selected tickets as spam' : 'Mark ticket as spam'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isMultiple ? (
          <>
            <div className="mb-3">
              This action will close the tickets, publish records to the
              transparency hub and notify the complainants via email.'
            </div>
            <div>{props.numberOfTickets} tickets will be marked as spam</div>
          </>
        ) : (
          'This action will close the ticket, publish a record to the transparency hub and notify the complainant via email.'
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="mark-spam-modal-footer d-flex justify-content-between align-items-center">
          <div
            onClick={() => setDoNotShowMessageAgain(!doNotShowMessageAgain)}
            className="do-not-show-checkbox no-text-select"
          >
            <Form.Group>
              <Form.Check
                className="c-pointer"
                checked={doNotShowMessageAgain}
                type="checkbox"
                label="Don't show this again"
                readOnly
              />
            </Form.Group>
          </div>
          <div className="mark-spam-footer-actions d-flex">
            <div
              onClick={() => props.closeCallback(false, false)}
              className="cancel-spam-button text-tertiary-light py-8 px-12 c-pointer no-text-select mr-12"
            >
              Cancel
            </div>
            <div
              className="mark-spam-button text-primary-dark py-8 px-12 c-pointer no-text-select"
              onClick={() => props.closeCallback(true, doNotShowMessageAgain)}
            >
              Mark as spam
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
