import { useEthers } from '@usedapp/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { UserContext } from 'context/UserContext';
import { LoginType } from 'models/Account';
import { useContext, useEffect } from 'react';
import useLogout from './useLogout';
import { useUpdateEffect } from 'usehooks-ts';

export default function useWalletWatch() {
  const { activate, active, account: connectedWalletAddress } = useEthers();
  const userContext = useContext(UserContext);
  const generalLogOut = useLogout();

  const injected = new InjectedConnector({ supportedChainIds: [1] });

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        activate(injected);
      }
    });
  }, [activate]);

  useUpdateEffect(() => {
    if (userContext.state.loginType !== LoginType.Wallet) return;
    if (
      userContext.state.account &&
      (!active ||
        connectedWalletAddress?.toLowerCase() !==
          userContext.state.account?.walletAddress)
    )
      return generalLogOut(LoginType.Wallet);
  }, [active, connectedWalletAddress]);

  return useWalletWatch;
}
