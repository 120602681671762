import { UserContext } from 'context/UserContext';
import useComplaint from 'library/hooks/useComplaint';
import ComplaintService from 'library/services/ComplaintService';
import { Complaint, ComplaintStatus } from 'models/Complaint';
import { FilterList } from 'models/FilterList';
import { Infringement } from 'models/Infringement';
import MarkSpamModal from 'modules/Common/Modals/MarkSpamModal/MarkSpamModal';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as icons from 'resources/icons';
import { useUpdateEffect } from 'usehooks-ts';
import { ComplaintActions } from './ComplaintActions/ComplaintActions';
import { ComplaintCids } from './ComplaintCids/ComplaintCids';
import { ComplaintDescription } from './ComplaintDescription/ComplaintDescription';
import { ComplaintDetails } from './ComplaintDetails/ComplaintDetails';
import { ComplaintNotes } from './ComplaintNotes/ComplaintNotes';
import {
  ComplaintRedactionReason,
  RedactionReasonOption,
} from './ComplaintRedactionReason/ComplaintRedactionReason';
import './ComplaintReview.css';
import { ComplaintTicketLog } from './ComplaintTicketLog/ComplaintTicketLog';
import { RelatedComplaints } from './RelatedComplaints/RelatedComplaints';
import SubmitTicketModal from './SubmitTicketModal/SubmitTicketModal';

const redactionChar = '*';

export const ComplaintReview = (): JSX.Element => {
  const navigate = useNavigate();
  const context = useContext(UserContext);
  const params = useParams();
  const [complaint, fetching, saveComplaint, submitComplaint, markAsSpam] =
    useComplaint(params && params._id ? parseInt(params._id) : 0);
  const [infringements, setInfringements] = useState<Infringement[]>([]);
  const [status, setStatus] = useState(ComplaintStatus.New);
  const [note, setNote] = useState<string>('');
  const [redactionReason, setRedactionReason] =
    useState<RedactionReasonOption | null>(null);
  const [badgeRenderObject, setBadgeRenderObject] = useState({
    badgeText: '',
    badgeCssClass: '',
  });
  const [canInteract, setCanInteract] = useState(false);
  const [isSpam, setIsSpam] = useState(false);
  const [selectedFilterLists, setSelectedFilterLists] = useState<
    Array<FilterList>
  >([]);
  const [showSubmitTicketModal, setShowSubmitTicketModal] = useState(false);
  const [showMarkSpamModal, setShowMarkSpamModal] = useState(false);
  const [selectedArea, setSelectedArea] = useState<string>('');
  const [redactedAreas, setRedactedAreas] = useState<
    Array<{
      rangeStart: number;
      rangeEnd: number;
    }>
  >([]);
  const redactedRef = useRef<
    Array<{
      rangeStart: number;
      rangeEnd: number;
    }>
  >([]);
  redactedRef.current = redactedAreas;

  useUpdateEffect(() => {
    if (!redactedAreas.length) setRedactionReason(null);
  }, [redactedAreas]);

  useEffect(() => {
    if (!complaint || (!complaint.status && complaint.status !== 0)) return;
    setInfringements(complaint.infringements);
    setStatus(complaint.status);
    setNote(complaint.privateNote);
    setSelectedFilterLists(complaint.filterLists);
    setIsSpam(complaint.isSpam);
    if (
      ![ComplaintStatus.Spam, ComplaintStatus.Resolved].includes(
        complaint.status
      )
    ) {
      setCanInteract(true);
    } else {
      setCanInteract(false);
    }
    if (complaint.redactionReason) {
      updatedRedactedAreas(complaint.redactedComplaintDescription);
      setRedactionReason(complaint.redactionReason);
    }

    const { badgeText, badgeCssClass } = ComplaintService.renderStatus(
      complaint.status
    );
    setBadgeRenderObject({
      badgeText: badgeText,
      badgeCssClass: badgeCssClass,
    });
  }, [complaint]);

  const updatedRedactedAreas = (redactedDescription: string) => {
    const splitRedactedDescription = redactedDescription.split('');
    const fetchedRedactedAreas: typeof redactedAreas = [];
    for (let i = 0; i < splitRedactedDescription.length; i++) {
      if (splitRedactedDescription[i] === redactionChar) {
        fetchedRedactedAreas.push({
          rangeStart: i,
          rangeEnd: i + 1,
        });
      }
    }
    setRedactedAreas(fetchedRedactedAreas);
  };

  const hasChanged = () => {
    for (let infringement of infringements) {
      const savedInfringement = complaint.infringements.find(
        (elem: Infringement) => elem._id === infringement._id
      );

      if (savedInfringement) {
        if (
          savedInfringement.accepted !== infringement.accepted ||
          savedInfringement.reason !== infringement.reason
        ) {
          return true;
        }
      }
    }

    if (selectedFilterLists.length !== complaint.filterLists.length) {
      return true;
    }

    return status !== complaint.status || note !== complaint.privateNote;
  };

  useEffect(() => {
    if (status === ComplaintStatus.New && hasChanged()) {
      setStatus(ComplaintStatus.UnderReview);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note, status, infringements]);

  const handleSpam = () => {
    if (
      context.state.config &&
      context.state.config.dontShow &&
      context.state.config.dontShow.includes('markAsSpamModal')
    ) {
      markSpamCallBack(true, true);
    } else {
      setShowMarkSpamModal(true);
    }
  };

  const setInfringement = (index: number, infringement: Infringement) => {
    let newInfringements = [...infringements];
    newInfringements[index] = { ...infringement };
    setInfringements([...newInfringements]);
  };

  const createRedactedDescription = () => {
    let redactedComplaintDescription = complaint.complaintDescription;
    const redactedAreas = [...redactedRef.current];
    for (let i = 0; i < redactedAreas.length; i++) {
      for (
        let j = redactedAreas[i].rangeStart;
        j < redactedAreas[i].rangeEnd;
        j++
      ) {
        redactedComplaintDescription =
          redactedComplaintDescription.substring(0, j) +
          redactionChar +
          redactedComplaintDescription.substring(j + 1);
      }
    }
    return redactedComplaintDescription;
  };

  const saveForLater = async () => {
    setCanInteract(false);

    let updatedComplaint = { ...complaint };
    updatedComplaint.infringements = infringements;
    updatedComplaint.status = ComplaintStatus.UnderReview;
    updatedComplaint.privateNote = note;
    updatedComplaint.filterLists = selectedFilterLists;

    if (redactedAreas.length) {
      if (!redactionReason) {
        setCanInteract(true);
        return toast.error(
          'You must select a redaction reason in order to save / submit the complaint.'
        );
      }
      updatedComplaint.redactedComplaintDescription =
        createRedactedDescription();
    } else {
      updatedComplaint.redactedComplaintDescription = '';
    }
    updatedComplaint.redactionReason = redactionReason;

    try {
      await saveComplaint(updatedComplaint);
    } catch (err) {
      return toast.error('Something went wrong! Please try again later');
    } finally {
      setCanInteract(true);
    }
  };

  const resolveAndPublish = async (canSubmit: boolean) => {
    if (!canSubmit) {
      return setShowSubmitTicketModal(false);
    }
    setCanInteract(false);
    setShowSubmitTicketModal(false);

    let updatedComplaint: Partial<Complaint> = {};
    updatedComplaint._id = complaint._id;
    updatedComplaint.infringements = infringements;
    updatedComplaint.status = ComplaintStatus.Resolved;
    updatedComplaint.privateNote = note;
    updatedComplaint.filterLists = selectedFilterLists;

    if (redactedAreas.length) {
      if (!redactionReason) {
        setCanInteract(true);
        return toast.error(
          'You must select a redaction reason in order to save / submit the complaint.'
        );
      }

      updatedComplaint.redactedComplaintDescription =
        createRedactedDescription();
    } else {
      updatedComplaint.redactedComplaintDescription = '';
    }
    updatedComplaint.redactionReason = redactionReason;

    try {
      await saveComplaint(updatedComplaint);

      await submitComplaint();
    } catch (err) {
      return toast.error('Something went wrong! Please try again later');
    } finally {
      setCanInteract(true);
    }
  };

  const markSpamCallBack = (
    canMarkSpam: boolean,
    doNotShowMessage: boolean
  ) => {
    if (canMarkSpam) {
      markAsSpam(doNotShowMessage);
    }
  };

  const redactArea = (selectedArea: string) => {
    if (!selectedArea) return;
    const { complaintDescription } = complaint;
    if (!complaintDescription.includes(selectedArea))
      return toast.error('Unable to redact current selection!');

    const splitComplaintDescription = complaintDescription.split(selectedArea);
    const selectionStartOffset = splitComplaintDescription[0].length;

    const selectionEndOffset =
      splitComplaintDescription[0].length + selectedArea.length;

    redactText(selectionStartOffset, selectionEndOffset);
  };

  const redactText = (rangeStart: number, rangeEnd: number) => {
    let substringStart = rangeStart;
    let substringEnd = rangeEnd;
    if (
      complaint.complaintDescription.substring(
        substringStart,
        substringStart + 1
      )[0] === ' '
    ) {
      substringStart += 1;
    }
    if (
      complaint.complaintDescription.substring(
        substringEnd - 1,
        substringEnd
      )[0] === ' '
    ) {
      substringEnd -= 1;
    }

    const currentRedactedAreas = [...redactedRef.current];
    const itemsToFilter: number[] = [];

    for (let i = 0; i < currentRedactedAreas.length; i++) {
      if (currentRedactedAreas[i].rangeStart === substringStart) {
        if (currentRedactedAreas[i].rangeEnd < substringEnd) {
          itemsToFilter.push(i);
        }

        if (currentRedactedAreas[i].rangeEnd > substringEnd) {
          currentRedactedAreas[i].rangeStart = substringEnd;
        }

        if (currentRedactedAreas[i].rangeEnd === substringEnd) {
          return;
        }
      }

      if (currentRedactedAreas[i].rangeStart < substringStart) {
        if (currentRedactedAreas[i].rangeEnd < substringStart) {
          continue;
        }

        if (currentRedactedAreas[i].rangeEnd <= substringEnd) {
          currentRedactedAreas[i].rangeEnd = substringStart;
        }

        if (currentRedactedAreas[i].rangeEnd > substringEnd) {
          return;
        }
      }

      if (currentRedactedAreas[i].rangeStart > substringStart) {
        if (currentRedactedAreas[i].rangeStart > substringEnd) {
          continue;
        }

        if (currentRedactedAreas[i].rangeEnd <= substringEnd) {
          itemsToFilter.push(i);
        }

        if (currentRedactedAreas[i].rangeEnd > substringEnd) {
          currentRedactedAreas[i].rangeStart = substringEnd;
        }
      }
    }

    const newRedactedAreas = [
      ...currentRedactedAreas.filter((e, i) => !itemsToFilter.includes(i)),
      {
        rangeStart: substringStart,
        rangeEnd: substringEnd,
      },
    ].sort((a, b) => a.rangeStart - b.rangeStart);

    setRedactedAreas(newRedactedAreas);
  };

  const resetRedactedContent = () => {
    setRedactedAreas([]);
  };

  return (
    <div className="complaint-review-container no-text-select">
      <div className="complaint-review-header py-28 px-32 d-flex align-items-center">
        <div
          onClick={() => navigate('/')}
          className="header-back-button d-flex justify-content-center align-items-center c-pointer no-text-select mr-16"
        >
          <img alt="back-icon" src={icons.backIcon}></img>
        </div>
        <div className="header-title flex-1 d-flex align-items-center">
          <div className="title-text fs-24 lh-32 fw-700 text-primary-light mr-8">
            Complaint record #{complaint._id}
          </div>
          <div
            className={`title-badge d-flex justify-content-center align-items-center badge-type-${badgeRenderObject.badgeCssClass} fw-500 fs-14 lh-20`}
          >
            {badgeRenderObject.badgeText}
          </div>
        </div>
        <div className="header-actions"></div>
        <div className="title-left-col d-flex align-items-center">
          {canInteract && (
            <Button
              className="review-header-button grey-button py-8 px-12 d-flex align-items-center justify-content-center mr-8 text-primary-dark c-pointer fw-500 fs-16 lh-24 no-text-select"
              onClick={() => saveForLater()}
            >
              Save for later
            </Button>
          )}

          {canInteract && (
            <div
              onClick={() => setShowSubmitTicketModal(true)}
              className="review-header-button py-8 px-12 d-flex align-items-center justify-content-center mr-8 text-primary-dark c-pointer fw-500 fs-16 lh-24 no-text-select"
            >
              Resolve and publish
            </div>
          )}
          {canInteract && (
            <Dropdown className="header-more-actions-dropdown">
              <Dropdown.Toggle className="header-more-actions-button d-flex align-items-center justify-content-center c-pointer no-text-select">
                <img alt="more-options-icon" src={icons.threeDots}></img>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleSpam()} eventKey="1">
                  Mark as spam
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        <div className="title-right-col"></div>
      </div>
      {fetching && (
        <div className="loading-state">
          <Spinner
            className="dashboard-spinner"
            animation="border"
            variant="primary"
          />
        </div>
      )}
      {!fetching && (
        <div className="complaint-review-content-container d-flex py-24 px-16">
          <div className="flex-1 complaint-review-content-left-section d-flex flex-column mr-24">
            <ComplaintDetails complaint={complaint} />
            <RelatedComplaints complaint={complaint} />
          </div>
          <div className="flex-2 complaint-review-content-center-section mr-24">
            <ComplaintDescription
              complaint={complaint}
              redactedAreas={redactedAreas}
              selectedArea={selectedArea}
              setSelectedArea={setSelectedArea}
              canInteract={canInteract}
            />
            <ComplaintCids
              infringements={infringements}
              setInfringement={setInfringement}
              canInteract={canInteract}
              isSpam={isSpam}
            />
          </div>
          <div className="flex-1 complaint-review-content-right-section">
            {canInteract && !isSpam ? (
              <ComplaintActions
                complaint={complaint}
                status={status}
                setStatus={setStatus}
                selectedFilterLists={selectedFilterLists}
                setSelectedFilterLists={(filterLists) =>
                  setSelectedFilterLists(filterLists)
                }
                canSubmit={canInteract}
              />
            ) : (
              <ComplaintTicketLog
                filterLists={selectedFilterLists}
                recordId={complaint._id || -1}
                resolvedOn={new Date(complaint.resolvedOn ?? '')}
                isSpam={isSpam}
              />
            )}
            {(canInteract || !!note) && (
              <ComplaintNotes
                note={note}
                setNote={setNote}
                canInteract={canInteract}
                isSpam={isSpam}
              />
            )}
            {(canInteract || !!redactedAreas.length) && (
              <ComplaintRedactionReason
                setRedactionReason={setRedactionReason}
                redactionReason={redactionReason}
                canInteract={canInteract}
                isSpam={isSpam}
                redactedAreas={redactedAreas}
                selectedArea={selectedArea}
                redactArea={redactArea}
                resetRedactedContent={resetRedactedContent}
              />
            )}
          </div>
        </div>
      )}
      <SubmitTicketModal
        filterListsSelected={!!selectedFilterLists.length}
        show={showSubmitTicketModal}
        closeCallback={(canSubmit, doNotShowMessage) => {
          resolveAndPublish(canSubmit);
        }}
      />
      <MarkSpamModal
        numberOfTickets={1}
        show={showMarkSpamModal}
        closeCallback={(canMarkSpam, doNotShowMessage) => {
          markSpamCallBack(canMarkSpam, doNotShowMessage);
          setShowMarkSpamModal(false);
        }}
      />
    </div>
  );
};
