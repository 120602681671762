import { Infringement } from './Infringement';
import { FilterList } from './FilterList';
import { RedactionReasonOption } from 'modules/ComplaintReview/ComplaintRedactionReason/ComplaintRedactionReason';

export enum FormActionTypes {
  Change = 'change',
  Toggle = 'toggle',
  Add = 'add',
  Remove = 'remove',
  Validate = 'validate',
}

interface FormAction {
  field: string;
  value: any;
  type: FormActionTypes;
}

export class FormError {
  field: string;
  error: string;

  constructor(field: string, error: string) {
    this.field = field;
    this.error = error;
  }
}

export enum ComplainantType {
  None,
  Individual,
  Organization,
  Government,
}

export enum FileType {
  NotAvailable = 'N/A',
  Text = 'text',
  Image = 'image',
  Video = 'video',
  Audio = 'audio',
  Other = 'other',
}

export enum OnBehalfOf {
  None,
  Self,
  OtherParty,
}

export enum ComplaintType {
  Copyright = 1,
  Inappropriate = 2,
  Other = 3,
}

export enum ComplaintStatus {
  'New',
  'UnderReview',
  'Resolved',
  'Spam',
}

export enum NetworkType {
  IPFS = 'IPFS',
  Filecoin = 'Filecoin',
}

export const GLOBAL_SCOPE = 'Global';

export class Complaint {
  _id?: number;
  title: string;
  fullName: string;
  companyName: string;
  email: string;
  phoneNumber: string;
  complainantType: ComplainantType;
  onBehalfOf: OnBehalfOf;
  address: string;
  city: string;
  state: string;
  country: string;
  type: ComplaintType;
  geoScope: string[];
  complaintDescription: string;
  redactedComplaintDescription: string;
  redactionReason: RedactionReasonOption | null;
  workDescription: string;
  infringements: Infringement[];
  agreement: boolean;
  created?: string;
  updated?: string;
  status: ComplaintStatus;
  privateNote: string;
  submitted: boolean;
  resolvedOn: Date | null;
  filterLists: FilterList[];
  isSpam: boolean;
  networks: NetworkType[];

  constructor() {
    this.fullName = '';
    this.title = '';
    this.companyName = '';
    this.email = '';
    this.phoneNumber = '';
    this.complainantType = ComplainantType.None;
    this.onBehalfOf = OnBehalfOf.None;
    this.address = '';
    this.city = '';
    this.state = '';
    this.country = '';
    this.type = ComplaintType.Copyright;
    this.geoScope = [GLOBAL_SCOPE];
    this.complaintDescription = '';
    this.redactedComplaintDescription = '';
    this.redactionReason = null;
    this.workDescription = '';
    this.infringements = [];
    this.agreement = false;
    this.status = ComplaintStatus.New;
    this.privateNote = '';
    this.filterLists = [];
    this.submitted = false;
    this.resolvedOn = null;
    this.isSpam = false;
    this.networks = [];
  }
}
