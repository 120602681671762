import { UserContext, UserContextActionTypes } from 'context/UserContext';
import { ApiService } from 'main/api/api.service';
import { Complaint } from 'models/Complaint';
import { useContext, useEffect, useState } from 'react';
import { Infringement } from '../../models/Infringement';
import { retrieveFileCids } from '../helpers/retrieveFileCids';

export default function useComplaint(
  complaintId: number
): [
  Complaint,
  boolean,
  (complaint: Partial<Complaint>) => Promise<void>,
  () => Promise<void>,
  (dontShow: boolean) => void
] {
  const apiService = ApiService();

  const [complaint, setComplaint] = useState(new Complaint());
  const [fetching, setFetching] = useState(true);
  const userContext = useContext(UserContext);

  const refresh = () => {
    apiService
      .getComplaintById(complaintId)
      .then(async (complaint: Complaint) => {
        const infringementsWithFilePromises: Infringement[] = [];
        complaint.infringements.forEach((infringement: Infringement) => {
          infringementsWithFilePromises.push({
            ...infringement,
            fileCidsPromise: retrieveFileCids(infringement.value),
          });
        });

        const infringementsWithFiles: Infringement[] = await Promise.all(
          infringementsWithFilePromises.map(
            async (
              infringementsWithFilePromise: Infringement
            ): Promise<Infringement> => ({
              ...infringementsWithFilePromise,
              fileCids: await infringementsWithFilePromise.fileCidsPromise,
            })
          )
        );

        const complaintWithFiles = {
          ...complaint,
          infringements: infringementsWithFiles,
        };

        setComplaint(complaintWithFiles);
        setFetching(false);
      });
  };
  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complaintId]);

  const save = async (complaint: Partial<Complaint>) => {
    await apiService.reviewComplaint(complaint);
    refresh();
  };

  const submit = async () => {
    await apiService.submitComplaint(complaint);
    refresh();
  };

  const markAsSpam = (dontShow: boolean = false) => {
    apiService.markComplaintAsSpam(complaint, dontShow).then(() => {
      if (
        userContext.state.config &&
        (!userContext.state.config.dontShow ||
          (userContext.state.config.dontShow &&
            !userContext.state.config.dontShow.includes('markAsSpamModal'))) &&
        dontShow
      ) {
        const currentConfig = userContext.state.config;
        userContext.dispatch({
          type: UserContextActionTypes.SetConfig,
          value: {
            ...currentConfig,
            dontShow: !userContext.state.config.dontShow
              ? ['markAsSpamModal']
              : [...currentConfig.dontShow, 'markAsSpamModal'],
          },
        });
      }

      if (!userContext.state.config) {
        apiService.getConfig().then((result) => {
          userContext.dispatch({
            type: UserContextActionTypes.SetConfig,
            value: result,
          });
        });
      }
      refresh();
    });
  };

  return [complaint, fetching, save, submit, markAsSpam];
}
