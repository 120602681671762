import ComplaintService from 'library/services/ComplaintService';
import { Complaint } from 'models/Complaint';
import { useContext, useState } from 'react';
import {
  Accordion,
  AccordionContext,
  useAccordionButton,
} from 'react-bootstrap';
import * as icons from 'resources/icons';
import './ComplaintDetails.css';
import '../ComplaintReview.css';

interface ComplaintDetailsProps {
  complaint: Complaint;
}

const DetailsListItem = (props: { title: string; value: string }) => {
  if (props.value.length === 0) {
    return <></>;
  }

  return (
    <div className="d-flex flex-column align-items-start fs-14 lh-20 too-long-text">
      <div className="pb-4px text-tertiary-light">{props.title}</div>
      <div className="pb-12 text-primary-light">{props.value}</div>
    </div>
  );
};

const ContextAwareToggle = (props: { eventKey: string; callback: any }) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    props.eventKey,
    () => props.callback && props.callback(props.eventKey)
  );

  const isCurrentEventKey = activeEventKey === props.eventKey;

  return (
    <div
      onClick={decoratedOnClick}
      className={`details-section-header d-flex align-items-center p-12 c-pointer no-text-select ${
        isCurrentEventKey ? '' : 'details-section-header-bottom-radius'
      }`}
    >
      <div className="details-section-arrow d-flex align-items-center pl-4px pr-16">
        <img
          alt="collapse-icon"
          src={isCurrentEventKey ? icons.downArrowGrey : icons.rightArrowGrey}
        ></img>
      </div>
      <div className="details-section-text text-primary-light fw-700 fs-16 lh-24">
        Details
      </div>
    </div>
  );
};

export const ComplaintDetails = (props: ComplaintDetailsProps): JSX.Element => {
  const [showMore, setShowMore] = useState(false);

  return (
    <Accordion className="details-section mb-24" defaultActiveKey="0">
      <ContextAwareToggle eventKey="0" callback={() => {}} />
      <Accordion.Collapse eventKey="0">
        <div className="accordion-bottom-border-radius d-flex flex-column">
          <div className="details-section-content p-16 pb-4px">
            <DetailsListItem
              title={'Name of the complainant'}
              value={props.complaint.fullName}
            />
            <DetailsListItem
              title={'Business name'}
              value={props.complaint.companyName}
            />
            <DetailsListItem
              title={'E-mail address'}
              value={props.complaint.email}
            />
            <DetailsListItem
              title={'Type of complaint'}
              value={ComplaintService.renderType(props.complaint.type)}
            />
            <DetailsListItem
              title={'Complainant type'}
              value={ComplaintService.renderComplainantType(
                props.complaint.complainantType
              )}
            />
            {showMore && (
              <>
                <DetailsListItem
                  title={'On behalf of'}
                  value={ComplaintService.renderOnBehalfOf(
                    props.complaint.onBehalfOf
                  )}
                />
                <DetailsListItem
                  title={'Phone number'}
                  value={props.complaint.phoneNumber}
                />
                <DetailsListItem
                  title={'Country / City'}
                  value={props.complaint.country + ', ' + props.complaint.city}
                />
                <DetailsListItem
                  title={'Created'}
                  value={props.complaint.created ? props.complaint.created : ''}
                />
                <DetailsListItem
                  title={'Networks'}
                  value={
                    props.complaint.networks
                      ? props.complaint.networks.join(', ')
                      : ''
                  }
                />
              </>
            )}
          </div>
          <div
            onClick={() => setShowMore(!showMore)}
            className="details-section-footer p-16 pt-0 c-pointer no-text-select fs-14 lh-20"
          >
            {showMore ? 'Show less' : 'Show more'}
          </div>
        </div>
      </Accordion.Collapse>
    </Accordion>
  );
};
