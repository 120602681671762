import { AxiosRequestConfig } from 'axios';
import { serverUri } from 'config';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';
import { Account, LoginType } from 'models/Account';
import { Complaint, NetworkType } from 'models/Complaint';
import { Config } from 'models/Config';
import { FilterList, Visibility } from 'models/FilterList';

export interface RelatedCidComplaint {
  infringement: string;
  complaints: Complaint[];
}

export interface RelatedComplaintsResponse {
  complainant: Complaint[];
  cids: RelatedCidComplaint[];
}

export { ApiService };

function ApiService() {
  const { apiHandler } = JWTInterceptor();
  const getComplaintById = async (id: number): Promise<Complaint> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/complaints/${id}`,
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  };

  const saveComplaint = async (complaint: Complaint): Promise<Complaint> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/complaints`,
      data: complaint,
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  };

  const reviewComplaint = async (
    complaint: Partial<Complaint>
  ): Promise<Complaint> => {
    const response = await apiHandler({
      method: 'put',
      url: `${serverUri()}/complaints/${complaint._id}`,
      data: complaint,
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  };

  const submitComplaint = async (complaint: Complaint): Promise<Complaint> => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/complaints/${complaint._id}/submit`,
      data: complaint,
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  };

  const markComplaintAsSpam = async (
    complaint: Complaint,
    dontShow: boolean = false
  ): Promise<any> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/complaints/mark-as-spam`,
      data: {
        complaintIds: [complaint._id],
        dontShowModal: dontShow,
      },
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  };

  const searchComplaints = async (
    query = '',
    itemsPerPage: number,
    page: number,
    order: string,
    orderBy: string
  ): Promise<{
    complaints: Complaint[];
    pageNumber: number;
    totalPages: number;
  }> => {
    const paramsObj: any = {};
    if (query) {
      paramsObj.q = query;
    }

    if (itemsPerPage) {
      paramsObj.itemsPerPage = itemsPerPage;
    }

    if (page || page === 0) {
      paramsObj.page = page + 1;
    }

    if (orderBy) {
      paramsObj.orderBy = orderBy;
    }

    if (order) {
      paramsObj.orderDirection = order.toUpperCase();
    }
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/complaints/search`,
      axiosConfig: {
        params: paramsObj as AxiosRequestConfig,
      },
    });

    if (response.error) {
      throw response.error;
    }

    const { complaints, page: pageNumber, totalPages } = response.data;

    return {
      complaints,
      pageNumber,
      totalPages,
    };
  };

  const getRelatedComplaints = async (
    id: number
  ): Promise<RelatedComplaintsResponse> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/complaints/${id}/related`,
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  };

  const getOwnedFilterLists = async (): Promise<{
    count: number;
    filters: FilterList[];
  }> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/filter`,
      axiosConfig: {
        params: {
          page: 0,
          perPage: 20,
          // sort: {
          //   name: "asc",
          // },
        },
      },
    });
    if (response.error) {
      throw response.error;
    }

    return response.data;
  };

  const markComplaintsAsSpam = async (
    complaintIds: Array<string>,
    dontShowModal = false
  ): Promise<{
    markedIds: Array<string>;
  }> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/complaints/mark-as-spam`,
      data: {
        complaintIds,
        dontShowModal,
      },
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  };

  const getConfig = async (): Promise<Config> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/config`,
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  };

  const getCidContent = async (cid: string): Promise<any> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/ipfs/get/${cid}`,
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  };

  const postNewFilterList = async (
    name: string,
    description: string,
    visibility: Visibility,
    networks: Array<NetworkType>
  ): Promise<any> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/filter`,
      data: {
        name,
        description,
        visibility,
        enabled: true,
        cids: [],
        networks,
      },
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  };

  const createNewProviderFilter = async (filterId: number): Promise<any> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/provider-filter`,
      data: {
        filterId,
        active: true,
      },
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  };

  const exportAccount = async (): Promise<any> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/assessor/export_assessor`,
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  };

  const deleteAssessor = async (): Promise<{ success: boolean }> => {
    const response = await apiHandler({
      method: 'delete',
      url: `${serverUri()}/assessor`,
      successMessage: 'You have successfully deleted your account!',
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  };

  const deleteProvider = async (): Promise<{ success: boolean }> => {
    const response = await apiHandler({
      method: 'delete',
      url: `${serverUri()}/provider`,
      successMessage: 'You have successfully deleted your account!',
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  };

  const linkWalletToGoogleAccount = async (tokenId: string): Promise<any> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/assessor/link-google/${tokenId
        .replace('/', '%2F')
        .replace(',', '%2C')}`,
      successMessage:
        'You have successfully linked your wallet account to the selected Google email!',
    });

    return response.data;
  };

  const generateNonceForSignature = async (
    wallet: string
  ): Promise<{
    nonceMessage: string;
    walletAddress: string;
  }> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/assessor/generate-nonce/${wallet}`,
    });

    return response.data;
  };

  const linkAssessorToWallet = async (
    wallet: string,
    signature: string
  ): Promise<Account> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/assessor/link-wallet/${wallet}`,
      data: {
        signature,
      },
      successMessage: 'Assessor account successfully linked to wallet address!',
    });

    return response.data;
  };

  const unlinkSecondAccountType = async (
    loginType: LoginType
  ): Promise<Account> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/assessor/unlink-second-login-type`,
      successMessage: `Successfully unlinked account from ${
        loginType === LoginType.Email ? 'wallet' : 'Google email'
      }!`,
    });

    return response.data;
  };

  return {
    getComplaintById,
    saveComplaint,
    reviewComplaint,
    submitComplaint,
    markComplaintAsSpam,
    searchComplaints,
    getRelatedComplaints,
    getOwnedFilterLists,
    markComplaintsAsSpam,
    getConfig,
    getCidContent,
    postNewFilterList,
    createNewProviderFilter,
    exportAccount,
    deleteAssessor,
    deleteProvider,
    linkWalletToGoogleAccount,
    generateNonceForSignature,
    linkAssessorToWallet,
    unlinkSecondAccountType,
  };
}
