import useWalletWatch from 'library/hooks/useWalletWatch';
import { AssessorInfoCheck } from 'modules/AssessorInfoCheck/AssessorInfoCheck';
import { useContext, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { UserContext } from './context/UserContext';
import { routes } from './main/routes';
import { TopBar } from './modules/Common/TopBar/TopBar';
import { Login } from './modules/Login/Login';
import './resources/styles/global.css';
import './resources/styles/helper.css';
import { Buffer } from 'buffer/';

function App() {
  useWalletWatch();

  useEffect(() => {
    window.Buffer = window.Buffer || Buffer;
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <div className="d-flex flex-column route-container">
          <TopBar />
          <Routes>
            <Route path="/login" element={<IsAuth />} />
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <RequireAuth redirectTo="/login">
                    <AssessorInfoCheck>{route.element}</AssessorInfoCheck>
                  </RequireAuth>
                }
              />
            ))}
          </Routes>
        </div>
      </BrowserRouter>
      <ToastContainer position="top-right" />
    </div>
  );
}

function RequireAuth({
  children,
  redirectTo,
}: {
  children: JSX.Element;
  redirectTo: string;
}) {
  const userContext = useContext(UserContext);
  const currentUserState = userContext.state;
  let isAuthenticated = currentUserState.account && currentUserState.config;
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

function IsAuth() {
  const userContext = useContext(UserContext);
  const currentUserState = userContext.state;
  let isAuthenticated = currentUserState.account && currentUserState.config;
  return !isAuthenticated ? <Login /> : <Navigate to="/" />;
}

export default App;
