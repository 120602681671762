import { Infringement } from 'models/Infringement';
import { Fragment, useEffect, useState } from 'react';
import { Dropdown, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as icons from 'resources/icons';
import CIDContentModal from './CIDContentModal/CIDContentModal';
import { CidRow, RowType } from './CidRow/CidRow';
import './ComplaintCids.css';
import { FileCidRow } from './FileCidRow/FileCidRow';

interface ComplaintCidsProps {
  infringements: Infringement[];
  setInfringement: (index: number, infringement: Infringement) => void;
  canInteract: boolean;
  isSpam: boolean;
}

export interface ContentModalObject {
  isOpen: boolean;
  infringementIndex: number;
  fileIndex: number;
}

export const ComplaintCids = (props: ComplaintCidsProps): JSX.Element => {
  const [checkList, setCheckList] = useState<Array<boolean>>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [contentModalObject, setContentModalObject] =
    useState<ContentModalObject>({
      isOpen: false,
      infringementIndex: 0,
      fileIndex: 0,
    });
  useEffect(() => {
    setCheckList(
      new Array<boolean>(props.infringements.length).fill(
        false,
        0,
        props.infringements.length
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCheckList(
      new Array<boolean>(props.infringements.length).fill(
        selectAll,
        0,
        props.infringements.length
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);

  const toggleCheck = (index: number) => {
    let updatedCheckList = [...checkList];
    updatedCheckList[index] = !updatedCheckList[index];
    setCheckList(updatedCheckList);
  };

  const setSelected = (accepted: boolean) => {
    for (let i = 0; i < props.infringements.length; i++) {
      if (!checkList[i]) {
        continue;
      }
      let infringement = props.infringements[i];
      infringement.accepted = accepted;
      props.setInfringement(i, { ...infringement });
    }
  };

  const openContentModal = (newContentModal: Partial<ContentModalObject>) => {
    setContentModalObject((prevState) => ({
      ...prevState,
      ...newContentModal,
      isOpen: true,
    }));
  };

  const onCloseContentModal = () => {
    setContentModalObject((prevState) => ({
      ...prevState,
      isOpen: false,
      infringementIndex: 0,
      fileIndex: 0,
    }));
  };

  const onPreviewNextFile = () => {
    if (
      Array.isArray(
        props.infringements[contentModalObject.infringementIndex].fileCids!
      )
    ) {
      if (
        contentModalObject.fileIndex + 1 >
        props.infringements[contentModalObject.infringementIndex].fileCids!
          .length -
          1
      ) {
        if (
          contentModalObject.infringementIndex + 1 >
          props.infringements.length - 1
        ) {
          setContentModalObject((prevState) => ({
            ...prevState,
            infringementIndex: 0,
            fileIndex: 0,
          }));
        } else {
          setContentModalObject((prevState) => ({
            ...prevState,
            infringementIndex: contentModalObject.infringementIndex + 1,
            fileIndex: 0,
          }));
        }
      } else {
        setContentModalObject((prevState) => ({
          ...prevState,
          fileIndex: contentModalObject.fileIndex + 1,
        }));
      }
    } else {
      if (
        contentModalObject.infringementIndex + 1 >
        props.infringements.length - 1
      ) {
        setContentModalObject((prevState) => ({
          ...prevState,
          infringementIndex: 0,
          fileIndex: 0,
        }));
      } else {
        setContentModalObject((prevState) => ({
          ...prevState,
          infringementIndex: contentModalObject.infringementIndex + 1,
          fileIndex: 0,
        }));
      }
    }
  };

  const onPreviewPreviousFile = () => {
    if (
      Array.isArray(
        props.infringements[contentModalObject.infringementIndex].fileCids!
      )
    ) {
      if (contentModalObject.fileIndex - 1 < 0) {
        if (contentModalObject.infringementIndex - 1 < 0) {
          setContentModalObject((prevState) => ({
            ...prevState,
            infringementIndex: props.infringements.length - 1,
            fileIndex:
              props.infringements[props.infringements.length - 1].fileCids!
                .length - 1,
          }));
        } else {
          setContentModalObject((prevState) => ({
            ...prevState,
            infringementIndex: contentModalObject.infringementIndex - 1,
            fileIndex:
              props.infringements[contentModalObject.infringementIndex - 1]
                .fileCids!.length - 1,
          }));
        }
      } else {
        setContentModalObject((prevState) => ({
          ...prevState,
          fileIndex: contentModalObject.fileIndex - 1,
        }));
      }
    } else {
      if (contentModalObject.infringementIndex - 1 < 0) {
        setContentModalObject((prevState) => ({
          ...prevState,
          infringementIndex: props.infringements.length - 1,
          fileIndex: 0,
        }));
      } else {
        setContentModalObject((prevState) => ({
          ...prevState,
          infringementIndex: contentModalObject.infringementIndex - 1,
          fileIndex: 0,
        }));
      }
    }
  };

  return (
    <>
      <div className="cids-section">
        <div className="cids-section-header d-flex justify-content-between align-items-center py-8 px-18">
          <div className="cids-section-header-checkbox-title d-flex">
            {!props.isSpam && props.canInteract && (
              <Form.Check
                type="checkbox"
                id={`check-all`}
                className="cids-section-header-checkbox mr-12"
                checked={selectAll}
                onChange={() => setSelectAll(!selectAll)}
              />
            )}
            <div className="cids-section-header-title fw-700 fs-16 lh-24">
              CID(s)
            </div>
          </div>
          <div className="cids-section-header-mark-infobox d-flex align-items-center">
            {checkList.filter((x) => x).length > 0 && (
              <Dropdown className="cids-section-header-mark-dropdown mr-10">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Mark {checkList.filter((x) => x).length} as {`  `}
                </Dropdown.Toggle>

                <Dropdown.Menu align="end">
                  <Dropdown.Item href="#" onClick={() => setSelected(true)}>
                    Accepted
                  </Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => setSelected(false)}>
                    Rejected
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            {!props.canInteract && (
              <div className="cids-section-header-infobox d-flex align-items-center mr-6 cursor-pointer">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      Copy CID(s) to clipboard
                    </Tooltip>
                  )}
                >
                  <img
                    style={{
                      filter:
                        'invert(60%) sepia(0%) saturate(1%) hue-rotate(207deg) brightness(96%) contrast(85%)',
                    }}
                    width={15}
                    alt="info-icon"
                    src={icons.clipboard}
                    onClick={() => {
                      let textForClipboard = ``;
                      textForClipboard = props.infringements
                        .filter((e) => e.accepted)
                        .map((e) => `${e.value}`)
                        .join('\n');
                      navigator.clipboard.writeText(textForClipboard);
                      toast.success('CID(s) copied to clipboard');
                    }}
                  />
                </OverlayTrigger>
              </div>
            )}
            {!props.isSpam && props.canInteract && (
              <div className="cids-section-header-infobox d-flex align-items-center">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      You can accept or reject included CIDs individually or in
                      bulk. If you add a rejection reason, it will be included
                      in reply to complainant, and to public record.
                    </Tooltip>
                  )}
                >
                  <img alt="info-icon" src={icons.info} />
                </OverlayTrigger>
              </div>
            )}
          </div>
        </div>
        <div className="cids-section-content">
          {props.infringements.map((infringement, index) => (
            <Fragment key={`cid-row-${index}`}>
              <CidRow
                infringement={infringement}
                index={index}
                checkList={checkList}
                toggleCheck={toggleCheck}
                setInfringement={props.setInfringement}
                canInteract={props.canInteract}
                isSpam={props.isSpam}
                openContentModal={openContentModal}
                rowType={
                  Array.isArray(infringement.fileCids)
                    ? RowType.Folder
                    : RowType.File
                }
              />
              {Array.isArray(infringement.fileCids) &&
                infringement.fileCids.map((fileCid, fileIndex) => {
                  return (
                    <FileCidRow
                      key={`file-cid-row-${fileCid}`}
                      openContentModal={openContentModal}
                      infringementIndex={index}
                      fileIndex={fileIndex}
                      fileCid={fileCid}
                    />
                  );
                })}
            </Fragment>
          ))}
        </div>
      </div>
      <CIDContentModal
        contentModalObject={contentModalObject}
        closeCallback={onCloseContentModal}
        infringement={props.infringements[contentModalObject.infringementIndex]}
        infringementsLength={props.infringements.length}
        setInfringement={props.setInfringement}
        canInteract={props.canInteract}
        isSpam={props.isSpam}
        onPreviewNextFile={onPreviewNextFile}
        onPreviewPreviousFile={onPreviewPreviousFile}
      />
    </>
  );
};
