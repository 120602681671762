import web3Storage from './web3Storage';
import { promiseSeparator } from './promiseSeparator';

export async function retrieveFileCids(
  cid: string
): Promise<string | string[] | undefined> {
  // web3.storage retrieval
  const [cidStatus, web3StorageError] = await promiseSeparator(
    web3Storage.status(cid)
  );
  if (cidStatus) {
    try {
      console.log(`Found ${cidStatus.pins.length} pins on IPFS.`);
      console.log(`Found ${cidStatus.deals.length} deals on Filecoin.`);

      const res = await web3Storage.get(cid);
      const files = await res?.files()!;
      return files.map((file) => file.cid);
    } catch (e) {
      console.log(`Web3.storage failed with error: ${e}`);
    }
  }
  console.log(`web3.storage retrieval failed with ${web3StorageError}`);

  // for ipfs gateway retrieval
  return cid;
}
