import { Form } from 'react-bootstrap';
import './ComplaintRedactionReason.css';

interface ComplaintRedactionReasonProps {
  setRedactionReason: React.Dispatch<
    React.SetStateAction<RedactionReasonOption | null>
  >;
  redactionReason: RedactionReasonOption | null;
  canInteract: boolean;
  isSpam: boolean;
  redactedAreas: Array<{
    rangeStart: number;
    rangeEnd: number;
  }>;
  selectedArea: string;
  redactArea: (substring: string) => React.ReactText | undefined;
  resetRedactedContent: () => void;
}

export enum RedactionReasonOption {
  PersonalData = 'Personal data',
}

export enum RedactionReasonEmptyOption {
  SelectAReason = 'undefined',
}

export const ComplaintRedactionReason = (
  props: ComplaintRedactionReasonProps
): JSX.Element => {
  const {
    redactionReason,
    setRedactionReason,
    isSpam,
    canInteract,
    redactedAreas,
    selectedArea,
    redactArea,
    resetRedactedContent,
  } = props;

  const changeRedactionReason = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === RedactionReasonEmptyOption.SelectAReason)
      setRedactionReason(null);
    setRedactionReason(event.target.value as RedactionReasonOption);
  };

  const mapRedactionReasonControlValue = () => {
    if (redactionReason === null)
      return RedactionReasonEmptyOption.SelectAReason;
    return redactionReason;
  };

  const canRedact = () => {
    if (selectedArea && !redactedAreas.length && canInteract) return true;
    return false;
  };

  const canClearRedaction = () => {
    if (redactedAreas.length && canInteract) return true;
    return false;
  };

  return (
    <div className="redaction-reason-section">
      <div className="redaction-reason-section-header d-flex justify-content-between py-12 px-16 fs-16 lh-24 fw-700">
        <div>Redaction</div>
      </div>
      <div className="redaction-reason-section-content p-16">
        <div className="mb-12">
          Select complaint text to redact it from public report. Choose reason
          below.
        </div>
        <div className="d-flex justify-content-around mb-12">
          <div
            className={`${
              canRedact() ? 'blue-button' : 'blue-button-disabled'
            } w-100 mr-12 text-primary-dark fw-500 fs-14 py-8 px-12 c-pointer no-text-select d-flex justify-content-center align-items-center`}
            onMouseDown={() => {
              if (canRedact()) redactArea(selectedArea);
            }}
          >
            Redact selection
          </div>
          <div
            className={`${
              canClearRedaction() ? 'red-button' : 'red-button-disabled'
            } text-primary-dark w-100 fw-500 fs-14 py-8 px-12 c-pointer no-text-select d-flex justify-content-center align-items-center`}
            onClick={() => {
              if (canClearRedaction()) resetRedactedContent();
            }}
          >
            Clear redaction
          </div>
        </div>
        {!isSpam && !!canInteract ? (
          <Form.Control
            as="select"
            placeholder="Select a reason"
            value={mapRedactionReasonControlValue()}
            disabled={!redactedAreas.length}
            onChange={changeRedactionReason}
          >
            <option value={RedactionReasonEmptyOption.SelectAReason}>
              Select a reason
            </option>
            <option value={RedactionReasonOption.PersonalData}>
              Personal data
            </option>
          </Form.Control>
        ) : (
          <div className="fs-14 lh-20 fw-600 text-primary-light">
            {redactionReason}
          </div>
        )}
      </div>
    </div>
  );
};
