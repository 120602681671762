import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { UserContext } from 'context/UserContext';
import { shortenAddress } from 'library/helpers/helper.functions';
import ComplaintService from 'library/services/ComplaintService';
import { parseInt } from 'lodash';
import { Complaint, ComplaintStatus, NetworkType } from 'models/Complaint';
import moment from 'moment';
import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as icons from 'resources/icons';
import { Data, Order } from '../Dashboard';
import './AssessmentQueueTable.css';
import { NetworkChips } from 'modules/Common/NetworkChips';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: '_id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'fullName',
    numeric: false,
    disablePadding: true,
    label: 'Sender',
  },
  {
    id: 'complaintDescription',
    numeric: false,
    disablePadding: true,
    label: 'Complaint',
  },
  {
    id: 'cid',
    numeric: false,
    disablePadding: true,
    label: 'CID(s)',
  },
  {
    id: 'networks',
    numeric: false,
    disablePadding: true,
    label: 'Networks',
  },
  {
    id: 'created',
    numeric: false,
    disablePadding: true,
    label: 'Date',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data | 'status'
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: keyof Data | 'status';
  unresolvedRowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    unresolvedRowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="none" align={'center'}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < unresolvedRowCount}
            checked={
              unresolvedRowCount > 0 && numSelected === unresolvedRowCount
            }
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        <TableCell padding="checkbox"></TableCell>

        {headCells.map((headCell) => (
          <TableCell
            className="fw-700 fs-16 lh-24"
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : undefined}
          >
            {!['cid', 'complaintDescription'].includes(headCell.id) &&
            headCell.id === orderBy ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        <TableCell padding="checkbox"></TableCell>
      </TableRow>
    </TableHead>
  );
}

interface AssessementQueueCustomTablePaginationProps {
  page: number;
  onPageChange: (page: number) => void;
  totalPages: number;
}

function AssessementQueueCustomTablePagination(
  props: AssessementQueueCustomTablePaginationProps
) {
  const [pagesArray, setPagesArray] = React.useState<Array<number>>([1]);
  const [usedPagesArray, setUsedPagesArray] = React.useState<Array<number>>([
    1,
  ]);

  React.useEffect(() => {
    const pagesArrayAux = [];
    for (let i = 0; i < props.totalPages; i++) {
      pagesArrayAux.push(i + 1);
    }

    setPagesArray(pagesArrayAux);
    setUsedPagesArray(pagesArrayAux.slice(0, 3));
  }, [props.totalPages]);

  React.useEffect(() => {
    const { page, totalPages } = props;
    let pagesArrayAux = [];
    const numberOfPagesToShow = totalPages > 3 ? 3 : totalPages;
    if (page === 0) {
      for (let i = 0; i < numberOfPagesToShow; i++) {
        pagesArrayAux.push(i + 1);
      }
    } else if (page + 1 === totalPages) {
      for (let i = numberOfPagesToShow; i > numberOfPagesToShow - 3; i--) {
        pagesArrayAux.push(i);
      }
    } else {
      pagesArrayAux = [page, page + 1, page + 2];
    }

    setUsedPagesArray(pagesArrayAux);
  }, [props.page]);

  return (
    <div className="pt-32 pb-32 mb-16 w-full d-flex justify-content-center align-items-center no-text-select">
      <div className="d-flex align-items-center c-pointer">
        <span
          onClick={() => {
            if (props.page === 0) return;
            props.onPageChange(props.page - 1);
          }}
          className={`prev-border text-tertiary-light px-8 py-6 fw-600 fs-14 lh-20 ${
            props.page === 0 ? '' : 'pagination-right-border'
          }`}
        >
          Previous
        </span>
        {usedPagesArray.map((e, index) => {
          return (
            <span
              onClick={() => {
                props.onPageChange(e - 1);
              }}
              className={`${
                e - 1 === props.page
                  ? 'selected-page'
                  : e - 1 === props.page - 1
                  ? ''
                  : 'pagination-right-border'
              } pagination-y-border text-tertiary-light px-8 py-6 fw-600 fs-14 lh-20`}
              key={'page-' + index}
            >
              {e.toString()}
            </span>
          );
        })}
        <span
          onClick={() => {
            if (props.page === pagesArray.length - 1) return;
            props.onPageChange(props.page + 1);
          }}
          className="next-border text-tertiary-light px-8 py-6 fw-600 fs-14 lh-20"
        >
          Next
        </span>
      </div>
    </div>
  );
}

interface AdjustedComplaint {
  _id: string;
  fullName: string;
  cid: string;
  created: number;
  complaintDescription: string;
  complaintStatus: ComplaintStatus;
  cidsLength: string;
  title: string;
  networks: NetworkType[];
}

interface AssessementQueueTableProps {
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  order: Order;
  setOrder: React.Dispatch<React.SetStateAction<Order>>;
  orderBy: keyof Data | 'status';
  setOrderBy: React.Dispatch<React.SetStateAction<keyof Data | 'status'>>;
  setMarkSpamModalObject: React.Dispatch<
    React.SetStateAction<{
      complaintIds: Array<string>;
      isMarkSpamOpen: boolean;
    }>
  >;
  markSpamNoModal: (complaintIds: Array<string>) => void;
  selected: string[];
  complaints: Complaint[];
  totalPages: number;
}

export default function AssessementQueueTable(
  props: AssessementQueueTableProps
) {
  const userContext = React.useContext(UserContext);
  const navigate = useNavigate();
  const { selected, setSelected, setMarkSpamModalObject } = props;

  const [adjustedComplaints, setAdjustedComplaints] = React.useState<
    AdjustedComplaint[]
  >([]);

  React.useEffect(() => {
    const auxAdjustedComplaints: AdjustedComplaint[] = [];

    props.complaints.forEach((e, i) => {
      const adjustedComplaint: AdjustedComplaint = {
        complaintDescription: e.complaintDescription,
        _id: e._id ? e._id.toString() : 'noId',
        cid: e.infringements.length ? e.infringements[0].value : 'N/A',
        fullName: e.fullName,
        created: moment(e.created).unix(),
        complaintStatus: e.status,
        cidsLength: e.infringements.length.toString(),
        title: e.title,
        networks: e.networks,
      };

      auxAdjustedComplaints.push(adjustedComplaint);
    });
    setAdjustedComplaints(auxAdjustedComplaints);
  }, [props.complaints]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data | 'status'
  ) => {
    const isAsc = props.orderBy === property && props.order === 'asc';
    props.setOrder(isAsc ? 'desc' : 'asc');
    props.setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = adjustedComplaints
        .filter((e) => !isComplaintResolved(e.complaintStatus))
        .map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (newPage: number) => {
    props.setPage(newPage);
  };

  const renderDate = (createdAt: number) => {
    const createdAtMoment = moment.unix(createdAt);
    const createdAtMomentStart = moment.unix(createdAt).startOf('day');
    const TODAY = moment().startOf('day');
    const YESTERDAY = TODAY.clone().subtract(1, 'days').startOf('day');
    const A_WEEK_OLD = TODAY.clone().subtract(7, 'days').startOf('day');
    const A_YEAR_OLD = TODAY.clone().subtract(365, 'days').startOf('day');

    const isToday = () => {
      return createdAtMomentStart.isSame(TODAY, 'd');
    };

    const isYesterday = () => {
      return createdAtMomentStart.isSame(YESTERDAY, 'd');
    };

    const isWithinAWeek = () => {
      return createdAtMomentStart.isAfter(A_WEEK_OLD);
    };

    const isWithinAYear = () => {
      return createdAtMomentStart.isAfter(A_YEAR_OLD);
    };

    switch (true) {
      case isToday():
        return 'Today' + ' ' + createdAtMoment.format('LT');

      case isYesterday():
        return 'Yesterday' + ' ' + createdAtMoment.format('LT');

      case isWithinAWeek():
        return (
          createdAtMoment.format('dddd') + ' ' + createdAtMoment.format('LT')
        );

      case isWithinAYear():
        return createdAtMoment.format('LL').split(',')[0];

      case !isWithinAYear():
        return createdAtMoment.format('LL');

      default:
        return '-';
    }
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const emptyRows =
    adjustedComplaints.length === 0 ? 13 : 15 - adjustedComplaints.length;

  const isComplaintResolved = (complaintStatus: ComplaintStatus) => {
    switch (complaintStatus) {
      case ComplaintStatus.New:
      case ComplaintStatus.UnderReview:
        return false;

      default:
        return true;
    }
  };

  return (
    <Box className="assessement-queue-table-box" sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={props.order}
              orderBy={props.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              unresolvedRowCount={
                adjustedComplaints.filter(
                  (e) => !isComplaintResolved(e.complaintStatus)
                ).length
              }
            />
            <TableBody>
              {adjustedComplaints.length === 0 && (
                <TableRow
                  style={{
                    height: 41 * 2,
                  }}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                >
                  <TableCell
                    colSpan={8}
                    className="no-data-found fs-24 fw-700"
                    component="th"
                    scope="row"
                    padding="none"
                  >
                    No data found for the requested query.
                  </TableCell>
                </TableRow>
              )}
              {adjustedComplaints.length > 0 &&
                adjustedComplaints.map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      onClick={() => navigate(`/complaint/${row._id}`)}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id + Math.random().toFixed(6)}
                      selected={isItemSelected}
                      className={`${
                        index % 2 === 0 ? 'alternative-row-color' : ''
                      } c-pointer`}
                    >
                      <TableCell
                        className="checkbox-cell"
                        onClick={(event) => {
                          if (isComplaintResolved(row.complaintStatus)) return;
                          handleClick(event, row._id);
                          event.stopPropagation();
                        }}
                        padding="none"
                        align={'center'}
                        width={'4%'}
                      >
                        {!isComplaintResolved(row.complaintStatus) ? (
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        ) : (
                          <div style={{ height: '40px' }}></div>
                        )}
                      </TableCell>
                      <TableCell width="1%" padding="none" align="right">
                        <div className="w-100 d-flex justify-content-end align-items-center pr-6">
                          <div
                            className={`d-flex fw-600 fs-10 lh-12 align-items-center justify-content-center status-badge badge-type-${
                              ComplaintService.renderStatus(row.complaintStatus)
                                .badgeCssClass
                            }`}
                          >
                            {
                              ComplaintService.renderStatus(row.complaintStatus)
                                .badgeLetter
                            }
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        component="th"
                        width="5%"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className="pr-2"
                      >
                        #{row._id}
                      </TableCell>
                      <TableCell
                        className="too-long-text"
                        width="12%"
                        padding="none"
                        align="left"
                      >
                        <span className="mr-10">
                          {row.fullName.length > 35
                            ? `${row.fullName.substring(0, 35)}...`
                            : row.fullName}
                        </span>
                      </TableCell>
                      <TableCell padding="none" align="left">
                        <span className="fw-600">{row.title}</span>
                        {` / `}
                        <span className="mr-10">
                          {row.complaintDescription.length > 55
                            ? `${row.complaintDescription.substring(0, 55)}...`
                            : row.complaintDescription}
                        </span>
                      </TableCell>
                      <TableCell width="4%" padding="none" align="left">
                        <div className="d-flex align-items-center">
                          {parseInt(row.cidsLength) >= 1 ? (
                            <span className="additional-cids-bullet mr-24">
                              {parseInt(row.cidsLength)}
                            </span>
                          ) : (
                            <span>0</span>
                          )}
                        </div>
                      </TableCell>
                      <TableCell width="10%" padding="none" align="left">
                        <NetworkChips networks={row.networks} />
                      </TableCell>
                      <TableCell width="10%" padding="none" align="left">
                        {renderDate(row.created)}
                      </TableCell>
                      <TableCell
                        width={'5%'}
                        className="assessment-mark-spam-cell-container"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        padding="none"
                        align="left"
                      >
                        {row.complaintStatus !== ComplaintStatus.Spam &&
                          row.complaintStatus !== ComplaintStatus.Resolved && (
                            <Dropdown>
                              <Dropdown.Toggle className="assessment-mark-spam-cell d-flex align-items-center c-pointer no-text-select">
                                <img
                                  alt="more-options-icon"
                                  width={20}
                                  src={icons.threeDots}
                                ></img>
                              </Dropdown.Toggle>
                              <Dropdown.Menu show={false} align={'end'}>
                                <Dropdown.Item
                                  onClick={() => {
                                    if (
                                      userContext.state.config &&
                                      userContext.state.config.dontShow &&
                                      userContext.state.config.dontShow.includes(
                                        'markAsSpamModal'
                                      )
                                    ) {
                                      props.markSpamNoModal([row._id]);
                                    } else {
                                      setMarkSpamModalObject({
                                        complaintIds: [row._id],
                                        isMarkSpamOpen: true,
                                      });
                                    }
                                  }}
                                  eventKey="1"
                                >
                                  Mark as spam
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 41 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <AssessementQueueCustomTablePagination
          onPageChange={handleChangePage}
          page={props.page}
          totalPages={props.totalPages}
        />
      </Paper>
    </Box>
  );
}
