export interface Provider {
  id?: number;
  walletAddressHashed?: string;
  country?: string;
  businessName?: string;
  website?: string;
  email?: string;
  contactPerson?: string;
  address?: string;
  nonce?: string;
  nonceMessage?: string;
  consentDate?: string;
  guideShown?: boolean;
  lastUpdate?: Date;
}

export interface BasicAuthInfo {
  nonceMessage: string;
  nonce: string;
  rodeoConsentDate: string;
}

export interface BasicAuthInfoEmail extends BasicAuthInfo {
  loginEmail: string;
}

export interface BasicAuthInfoWallet extends BasicAuthInfo {
  walletAddressHashed: string;
}

export interface Account {
  id: string;
  walletAddress?: string;
  accessToken?: string;
  provider: Provider;
  rodeoConsentDate: string;
  nonceMessage: string;
  nonce: string;
  loginEmail: string;
  walletAddressHashed?: string;
}

export enum LoginType {
  Wallet = 1,
  Email = 2,
}
