import { Infringement } from 'models/Infringement';
import React, { useEffect, useState } from 'react';
import { Form, Modal, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import * as icons from 'resources/icons';
import './CIDContentModal.css';
import { ContentModalObject } from '../ComplaintCids';
import { ContentPreview } from './ContentPreview';
import { FileType } from '../../../../models/Complaint';

interface CIDContentModalProps {
  contentModalObject: ContentModalObject;
  closeCallback: () => void;
  infringement: Infringement;
  infringementsLength: number;
  setInfringement: (index: number, infringement: Infringement) => void;
  canInteract: boolean;
  isSpam: boolean;
  onPreviewNextFile: () => void;
  onPreviewPreviousFile: () => void;
}

export default function CIDContentModal(
  props: CIDContentModalProps
): JSX.Element {
  const [cid, setCid] = useState<string>('');
  const [fileType, setFileType] = useState<FileType>(FileType.NotAvailable);

  useEffect(() => {
    const handleKeyPress = ({ keyCode }: KeyboardEvent) => {
      if (keyCode === 37) {
        props.onPreviewPreviousFile();
      }
      if (keyCode === 39) {
        props.onPreviewNextFile();
      }
    };
    window.addEventListener('keyup', handleKeyPress);
    return () => {
      window.removeEventListener('keyup', handleKeyPress);
    };
  });

  useEffect(() => {
    if (Array.isArray(props.infringement.fileCids)) {
      setCid(props.infringement.fileCids[props.contentModalObject.fileIndex]);
    } else {
      setCid(props.infringement.fileCids as string);
    }
    setFileType(props.infringement.fileType!);
  }, [
    props.contentModalObject.fileIndex,
    props.infringement.fileCids,
    props.infringement.fileType,
  ]);
  return (
    <Modal
      dialogClassName="content-modal-70w"
      show={props.contentModalObject.isOpen}
      onHide={() => {
        props.closeCallback();
      }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="content-modal-body">
        <div
          onClick={() => props.onPreviewPreviousFile()}
          className="c-pointer next-previous-cid-arrow-container content-previous-arrow no-text-select"
        >
          <img
            alt="previous-cid"
            className="c-pointer next-previous-cid-arrow"
            src={icons.previousCid}
          />
        </div>
        <div
          onClick={() => props.onPreviewNextFile()}
          className="c-pointer next-previous-cid-arrow-container content-next-arrow no-text-select"
        >
          <img alt="next-cid" src={icons.nextCid} />
        </div>
        <ContentPreview cid={cid} fileType={fileType} />
      </Modal.Body>
      <Modal.Footer className="content-modal-footer">
        <div className="content-modal-cid-row-container">
          <div className="py-8 px-16 d-flex align-items-center">
            <div className="content-modal-cid-row-text-actions justify-content-between d-flex align-items-center flex-1">
              <div className="content-modal-cid-row-text too-long-text mr-10">
                {cid}
              </div>
              <div className="content-modal-cid-row-actions d-flex align-items-center mr-24">
                {!props.isSpam &&
                  props.canInteract &&
                  !Array.isArray(props.infringement.fileCids) && (
                    <img
                      alt="comment-icon"
                      onClick={() => {
                        if (
                          props.infringement &&
                          typeof props.infringement.reason === 'string'
                        ) {
                          props.setInfringement(
                            props.contentModalObject.infringementIndex,
                            {
                              ...props.infringement,
                              reason: undefined,
                            }
                          );
                        } else {
                          props.setInfringement(
                            props.contentModalObject.infringementIndex,
                            {
                              ...props.infringement,
                              reason: '',
                            }
                          );
                        }
                      }}
                      className="mr-12 c-pointer no-text-select"
                      src={
                        props.infringement &&
                        typeof props.infringement.reason === 'string'
                          ? icons.messageBlue
                          : icons.messageGrey
                      }
                    />
                  )}
                <img
                  alt="cid-content-in-new--icon"
                  className="c-pointer no-text-select"
                  src={icons.external}
                  onClick={() =>
                    window.open(`https://${cid}.ipfs.w3s.link/`, '_blank')
                  }
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </div>
            <div className="content-modal-cid-row-accept-reject">
              {!props.isSpam &&
                !Array.isArray(props.infringement.fileCids) &&
                (props.canInteract ? (
                  <ToggleButtonGroup
                    type="radio"
                    name={`accepted-${props.infringement?._id}`}
                    value={props.infringement?.accepted ? 1 : 0}
                    onChange={(e) => {
                      props.setInfringement(
                        props.contentModalObject.infringementIndex,
                        {
                          ...props.infringement,
                          accepted: e === 1,
                        }
                      );
                    }}
                  >
                    <ToggleButton
                      className={`${
                        props.infringement?.accepted
                          ? 'white-background'
                          : 'blue-background'
                      }`}
                      id={`radio-decline-${props.infringement?._id}`}
                      value={0}
                    >
                      <img
                        alt="reject-cid-icon"
                        src={
                          props.infringement?.accepted
                            ? icons.blueX
                            : icons.whiteX
                        }
                      />
                    </ToggleButton>

                    <ToggleButton
                      className={
                        !props.infringement?.accepted
                          ? 'content-modal-white-background'
                          : 'content-modal-blue-background'
                      }
                      id={`radio-accept-${props.infringement?._id}`}
                      value={1}
                    >
                      <img
                        alt="accept-cid-icon"
                        src={
                          !props.infringement?.accepted
                            ? icons.blueTick
                            : icons.whiteTick
                        }
                      />
                    </ToggleButton>
                  </ToggleButtonGroup>
                ) : (
                  <div className="content-modal-resolved-cid-row-accept-reject fs-14 lh-20 fw-500">
                    {props.infringement?.accepted ? (
                      <span className="content-modal-resolved-cid-row-accepted">
                        Accepted
                      </span>
                    ) : (
                      <div className="content-modal-resolved-cid-row-rejected">
                        Rejected
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>

          {props.infringement &&
            !props.isSpam &&
            typeof props.infringement.reason === 'string' && (
              <div className="mt-8 pb-12 pr-16">
                {props.canInteract ? (
                  <div className="pl-20">
                    <div className="text-primary-light fs-14 lh-20 fw-700 mb-8">
                      Add a reason
                    </div>
                    <div className="">
                      <Form.Control
                        rows={3}
                        as="textarea"
                        placeholder="Reason"
                        value={props.infringement?.reason}
                        onChange={(e) => {
                          props.setInfringement(
                            props.contentModalObject.infringementIndex,
                            {
                              ...props.infringement,
                              reason: e.target.value,
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="pl-16 fs-14 lh-20 text-secondary-light">
                    {props.infringement ? props.infringement.reason : 'N/A'}
                  </div>
                )}
              </div>
            )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
