import { Config, DAppProvider, Mainnet } from '@usedapp/core';
import 'bootstrap/dist/css/bootstrap.min.css';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { UserContextProvider } from './context/UserContext';
import { UtilityContextProvider } from './context/UtilityContext';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { rodeoGoogleClientId } from 'config';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(en);

const config: Config = {
  readOnlyChainId: Mainnet.chainId,
};

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={rodeoGoogleClientId}>
      <UserContextProvider>
        <DAppProvider config={config}>
          <UtilityContextProvider>
            <App />
          </UtilityContextProvider>
        </DAppProvider>
      </UserContextProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
