import { UserContext } from 'context/UserContext';
import ComplaintService from 'library/services/ComplaintService';
import { ApiService } from 'main/api/api.service';
import { Complaint, ComplaintStatus } from 'models/Complaint';
import { FilterList, Visibility } from 'models/FilterList';
import { AntSwitch } from 'modules/Common/HTMLSwitchComponent';
import { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as icons from 'resources/icons';
import BitScreenListModal from './BitScreenListModal/BitScreenListModal';
import './ComplaintActions.css';

interface ComplaintActionsProps {
  complaint: Complaint;
  status: ComplaintStatus;
  setStatus: (status: ComplaintStatus) => void;
  selectedFilterLists: Array<any>;
  setSelectedFilterLists: (filterLists: Array<any>) => void;
  canSubmit: boolean;
}

export const ComplaintActions = (props: ComplaintActionsProps): JSX.Element => {
  const apiService = ApiService();
  const context = useContext(UserContext);
  const { complaint, canSubmit, setSelectedFilterLists, selectedFilterLists } =
    props;
  const [addToFilterList, setAddToFilterList] = useState(false);
  const [showBitScreenListModal, setShowBitScreenListModal] = useState(false);
  const [filterListOptions, setFilterListOptions] = useState<FilterList[]>([]);

  useEffect(() => {
    apiService
      .getOwnedFilterLists()
      .then((result: { count: number; filters: FilterList[] }) => {
        setFilterListOptions(
          result.filters.filter(
            (e) => e.provider?.id === context.state.account?.provider.id
          )
        );
      });
    if (selectedFilterLists.length > 0) {
      setAddToFilterList(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bitscreenListModalClose = ({
    name,
    description,
    listType,
    shouldCreate,
  }: {
    name: string;
    description: string;
    listType: Visibility | null;
    shouldCreate: boolean;
  }) => {
    if (!shouldCreate || !listType) {
      return setShowBitScreenListModal(false);
    }

    apiService
      .postNewFilterList(name, description, listType, complaint.networks)
      .then((filter) => {
        apiService
          .getOwnedFilterLists()
          .then((result: { count: number; filters: FilterList[] }) => {
            setFilterListOptions(
              result.filters.filter(
                (e) => e.provider?.id === context.state.account?.provider.id
              )
            );
          });
      })
      .catch((err) => {
        toast.error(
          'Something went wrong with creating the new filter list! Please try again!'
        );
      })
      .finally(() => {
        setShowBitScreenListModal(false);
      });
  };

  return (
    <div className="actions-section mb-24">
      <div className="actions-section-header fs-16 lh-24 fw-700 p-12 pl-16">
        Actions
      </div>
      <div className="actions-section-content p-16">
        <div className="add-to-filter-list-section d-flex flex-column">
          <div className="add-to-filter-list-toggle mb-8 d-flex justify-content-between">
            <div className="fs-14 lh-20 fw-700">Add to filter list</div>
            <AntSwitch
              color="primary"
              checked={addToFilterList}
              onChange={() => {
                setAddToFilterList(!addToFilterList);
                setSelectedFilterLists([]);
              }}
              name="add-to-filter-list"
            />
          </div>
          {addToFilterList && (
            <div className="add-to-filter-list-dropdown mb-12">
              <Dropdown>
                <Dropdown.Toggle
                  className={`toggle-complaint-actions list-select-toggle text-primary-light fs-14 lh-20`}
                >
                  Select a list
                </Dropdown.Toggle>

                <Dropdown.Menu className="toggle-menu">
                  {!!filterListOptions.filter(
                    (el) =>
                      !selectedFilterLists.includes(el) &&
                      el.visibility !== Visibility.Exception
                  ).length && (
                    <>
                      {filterListOptions
                        .filter(
                          (el) =>
                            !selectedFilterLists.includes(el) &&
                            el.visibility !== Visibility.Exception
                        )
                        .map((e, index) => (
                          <Dropdown.Item
                            onClick={() => {
                              const array = [...selectedFilterLists, e];
                              setSelectedFilterLists(array);
                            }}
                            key={`filter-list-item-${index}`}
                          >
                            {e.name}
                          </Dropdown.Item>
                        ))}
                      <Dropdown.Divider></Dropdown.Divider>
                    </>
                  )}
                  <Dropdown.Item
                    onClick={() => setShowBitScreenListModal(true)}
                    className="bitscreen-list"
                  >
                    Create new list in BitScreen
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          <div className="add-to-filter-list-items">
            {selectedFilterLists.map((e, i) => (
              <div
                className={`d-flex justify-content-between align-items-center selected-filter-list-item-${i} px-12 fs-12 lh-20 fw-600 mb-8`}
              >
                {e.name}
                <div className="remove-filter-list c-pointer no-text-select">
                  <img
                    alt="remove-list-icon"
                    onClick={() => {
                      const array = [...selectedFilterLists].filter(
                        (element, index) => index !== i
                      );
                      setSelectedFilterLists(array);
                    }}
                    src={icons.removeList}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {canSubmit && (
          <div className="submit-note py-12 px-16">
            <div className="submit-note-title text-primary-light fw-700 fs-14 lh-20 mb-8">
              What happens when you submit
            </div>
            <div className="submit-note-text text-secondary-light fs-12 lh-16">
              An email reply will be sent to the complainant, and a public
              transparency record will be published, with contact info removed.
            </div>
          </div>
        )}
      </div>
      <BitScreenListModal
        show={showBitScreenListModal}
        closeCallback={(closeObject) => bitscreenListModalClose(closeObject)}
      />
    </div>
  );
};
