export const serverUri = (): string => {
  const environment = process.env.NODE_ENV;
  switch (environment) {
    case 'development':
      return process.env.REACT_APP_HOST || 'http://localhost:3030';
    case 'production':
      return 'https://backend.bitscreen.co';
    default:
      return 'https://backend.bitscreen.co';
  }
};

export const bitscreenUri = (): string => {
  const environment = process.env.NODE_ENV;
  switch (environment) {
    case 'development':
      return 'http://localhost:13000';
    case 'production':
      return 'https://app.bitscreen.co';
    default:
      return 'https://app.bitscreen.co';
  }
};

export const lookingGlassUri = (): string => {
  const environment = process.env.NODE_ENV;
  switch (environment) {
    case 'development':
      return 'http://localhost:15000';
    case 'production':
      return 'https://glass.bitscreen.co';
    default:
      return 'https://glass.bitscreen.co';
  }
};

export const rodeoGoogleClientId =
  '835783680602-glvi9n18ikstgu0aeleajpsiakd1hqn6.apps.googleusercontent.com';
