import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as icons from 'resources/icons';
import './ComplaintNotes.css';

interface ComplaintNotesProps {
  note: string;
  setNote: (note: string) => void;
  canInteract: boolean;
  isSpam: boolean;
}

export const ComplaintNotes = (props: ComplaintNotesProps): JSX.Element => {
  const renderTooltip = (props: any) => (
    <Tooltip className="notes-tooltip" {...props}>
      You can add a private note that will be saved with the ticket. It will not
      be published or sent to complainant.
    </Tooltip>
  );

  return (
    <div className="notes-section mb-24">
      <div className="notes-section-header d-flex justify-content-between py-12 px-16 fs-16 lh-24 fw-700">
        <div>Private note</div>
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <img alt="info-icon" src={icons.info} />
        </OverlayTrigger>
      </div>
      <div className="notes-section-content p-16">
        {!props.isSpam && props.canInteract ? (
          <Form.Control
            className="note-textarea"
            rows={1}
            as="textarea"
            placeholder="Write a note..."
            value={props.note || ''}
            onChange={(e) => props.setNote(e.target.value)}
          />
        ) : (
          <div className="fs-14 lh-20 text-secondary-light">{props.note}</div>
        )}
      </div>
    </div>
  );
};
