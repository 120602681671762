import { UserContext, UserContextActionTypes } from 'context/UserContext';
import { UtilityContext } from 'context/UtilityContext';
import { ApiService } from 'main/api/api.service';
import { Complaint, ComplaintStatus, NetworkType } from 'models/Complaint';
import MarkSpamModal from 'modules/Common/Modals/MarkSpamModal/MarkSpamModal';
import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useUpdateEffect } from 'usehooks-ts';
import AssessementQueueTable from './AssessmentQueueTable/AssessmentQueueTable';
import './Dashboard.css';

export interface Data {
  _id: string;
  fullName: string;
  complaintDescription: string;
  cid: string;
  created: number;
  cidsLength: string;
  complaintStatus: ComplaintStatus;
  networks: NetworkType[];
}

export type Order = 'asc' | 'desc';

export const Dashboard = (props: any): JSX.Element => {
  const apiService = ApiService();
  const utilityContext = useContext(UtilityContext);
  const userContext = useContext(UserContext);

  const [page, setPage] = React.useState(0);
  const [complaintsObject, setComplaintsObject] = useState<{
    complaints: Complaint[];
    totalPages: number;
  }>(
    props.testing && props.testing.complaints
      ? {
          complaints: props.testing.complaints,
          totalPages: props.testing.totalPages,
        }
      : {
          complaints: [],
          totalPages: 0,
        }
  );
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data | 'status'>(
    'created'
  );
  const [loaded, setLoaded] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [markSpamModalObject, setMarkSpamModalObject] = useState<{
    complaintIds: Array<string>;
    isMarkSpamOpen: boolean;
  }>({
    complaintIds: [],
    isMarkSpamOpen: false,
  });

  const markSpamNoModal = (complaintIds: Array<string>) => {
    if (!userContext.state.account) return;
    apiService.markComplaintsAsSpam(complaintIds, true).then(() => {
      fetchComplaints();
    });
  };

  const markSpamCallBack = (canMarkSpam: boolean, dontShow: boolean) => {
    if (!canMarkSpam || !userContext.state.account) return;
    apiService
      .markComplaintsAsSpam(markSpamModalObject.complaintIds, dontShow)
      .then(() => {
        if (
          userContext.state.config &&
          (!userContext.state.config.dontShow ||
            (userContext.state.config.dontShow &&
              !userContext.state.config.dontShow.includes(
                'markAsSpamModal'
              ))) &&
          dontShow
        ) {
          const currentConfig = userContext.state.config;
          userContext.dispatch({
            type: UserContextActionTypes.SetConfig,
            value: {
              ...currentConfig,
              dontShow: !userContext.state.config.dontShow
                ? ['markAsSpamModal']
                : [...currentConfig.dontShow, 'markAsSpamModal'],
            },
          });
        }

        if (!userContext.state.config) {
          apiService.getConfig().then((result) => {
            userContext.dispatch({
              type: UserContextActionTypes.SetConfig,
              value: result,
            });
          });
        }
        fetchComplaints();
        setMarkSpamModalObject({
          complaintIds: [],
          isMarkSpamOpen: false,
        });
      });
  };

  const fetchComplaints = async (): Promise<void> => {
    try {
      var { complaints, totalPages } = await apiService.searchComplaints(
        utilityContext.state.queryObject.searchTerm,
        15,
        page,
        order,
        orderBy
      );
    } catch (e: any) {
      toast.error(e);
      return;
    }
    setComplaintsObject({
      complaints: [...complaints],
      totalPages,
    });
    setLoaded(true);
  };

  useEffect(() => {
    if (props.testing && props.testing.fetchMock) {
      return props.testing.fetchMock();
    }
    fetchComplaints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateEffect(() => {
    fetchComplaints();
  }, [page, order, orderBy, utilityContext.state.queryObject.searchTerm]);

  if (!loaded && (!props.testing || !props.testing.loaded)) {
    return (
      <div className="loading-state">
        <Spinner
          className="dashboard-spinner"
          animation="border"
          variant="primary"
        />
      </div>
    );
  }

  return (
    <>
      <div className="inbound-complaints-page-container">
        <div className="inbound-complaints-page-header d-flex align-items-center justify-content-between pt-32 pb-24 px-32 fs-24 fw-700 text-primary-light">
          <div
            aria-label="dashboard-page-title-text"
            className="inbound-complaints-page-header-title lh-36 d-flex align-items-center"
          >
            New inbound complaints{' '}
            <span
              aria-label="dashboard-page-tickets-text"
              className="ml-8 fs-12 lh-16 text-secondary-light fw-400"
            >
              {complaintsObject.complaints.length} tickets
            </span>
          </div>
          <div className="d-flex">
            {selected.length > 0 && (
              <Dropdown
                aria-label="dashboard-page-edit-dropdown"
                className="dashboard-mark-spam-dropdown mr-10"
              >
                <Dropdown.Toggle
                  className="fw-600 fs-14 lh-20 d-flex align-items-center justify-content-around"
                  variant="success"
                  id="dropdown-basic"
                >
                  Edit {selected.length} tickets
                </Dropdown.Toggle>

                <Dropdown.Menu align="end">
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      if (
                        userContext.state.config &&
                        userContext.state.config.dontShow &&
                        userContext.state.config.dontShow.includes(
                          'markAsSpamModal'
                        )
                      ) {
                        markSpamNoModal(selected.map((e) => e));
                      } else {
                        setMarkSpamModalObject({
                          complaintIds: selected.map((e) => e),
                          isMarkSpamOpen: true,
                        });
                      }
                    }}
                  >
                    Mark as spam
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            <div>
              <Dropdown
                aria-label="dashboard-page-order-by-dropdown"
                className="mr-10"
              >
                <Dropdown.Toggle
                  className="order-by-dropdown fw-600 fs-14 lh-20 d-flex align-items-center justify-content-around text-primary-light"
                  variant="outline-secondary"
                  id="dropdown-basic"
                >
                  Order by
                </Dropdown.Toggle>

                <Dropdown.Menu align="end">
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      setOrder('asc');
                      setOrderBy('status');
                    }}
                  >
                    Status
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      setOrder('desc');
                      setOrderBy('created');
                    }}
                  >
                    Date
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      setOrder('asc');
                      setOrderBy('fullName');
                    }}
                  >
                    Sender
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      setOrder('desc');
                      setOrderBy('_id');
                    }}
                  >
                    ID
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="inbound-complaints-page-table">
          <AssessementQueueTable
            complaints={complaintsObject.complaints}
            totalPages={complaintsObject.totalPages}
            setSelected={setSelected}
            selected={selected}
            setMarkSpamModalObject={setMarkSpamModalObject}
            markSpamNoModal={markSpamNoModal}
            setPage={setPage}
            page={page}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            order={order}
            setOrder={setOrder}
          />
        </div>
      </div>
      <MarkSpamModal
        numberOfTickets={markSpamModalObject.complaintIds.length}
        show={markSpamModalObject.isMarkSpamOpen}
        closeCallback={(canMarkSpam: boolean, doNotShowMessage: boolean) => {
          if (!canMarkSpam) {
            return setMarkSpamModalObject({
              complaintIds: [],
              isMarkSpamOpen: false,
            });
          }
          markSpamCallBack(canMarkSpam, doNotShowMessage);
        }}
      />
    </>
  );
};
