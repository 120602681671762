import { serverUri } from 'config';
import {
  Account,
  BasicAuthInfoEmail,
  BasicAuthInfoWallet,
  LoginType,
  Provider,
} from 'models/Account';
import { JWTInterceptor } from 'library/helpers/jwtInterceptor';

export { AuthService };

function AuthService() {
  const { apiHandler } = JWTInterceptor();

  const getAuthInfoWallet = async (
    wallet: string
  ): Promise<BasicAuthInfoWallet | null> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/assessor/auth_info/${wallet.toLowerCase()}`,
    });

    if (!response.data || response.error) {
      return null;
    }

    return {
      ...response.data,
      walletAddress: wallet,
    };
  };

  const getAuthInfoEmail = async (
    tokenId: string
  ): Promise<BasicAuthInfoEmail | null> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/assessor/auth_info/email/${tokenId
        .replace('/', '%2F')
        .replace(',', '%2C')}`,
    });

    if (!response.data || response.error) {
      return null;
    }

    return {
      ...response.data,
    };
  };

  const getAssessorWithProviderAccount = async (
    walletAddress?: string
  ): Promise<Account | null> => {
    const response = await apiHandler({
      method: 'get',
      url: `${serverUri()}/assessor/with_provider`,
    });

    if (!response.data || response.error) {
      return null;
    }

    return {
      ...response.data,
      walletAddress,
    };
  };

  const createAssessor = async (wallet: string): Promise<Account> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/assessor/create/${wallet.toLowerCase()}`,
    });

    if (response.error) {
      throw response.error;
    }

    return response?.data;
  };

  const createAssessorByEmail = async (tokenId: string): Promise<Account> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/assessor/create/email/${tokenId
        .replace('/', '%2F')
        .replace(',', '%2C')}`,
    });

    if (response.error) {
      throw response.error;
    }

    return response?.data;
  };

  const authenticateAssessor = async (
    wallet: string,
    signature: string
  ): Promise<Account> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/assessor/auth/wallet/${wallet.toLowerCase()}`,
      data: {
        signature: signature,
      },
      successMessage: 'Successfully logged in!',
      errorMessage: 'We could not log you in! Please try again!',
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  };

  const authenticateAssessorByEmail = async (
    tokenId: string
  ): Promise<Account> => {
    const response = await apiHandler({
      method: 'post',
      url: `${serverUri()}/assessor/auth/email/${tokenId
        .replace('/', '%2F')
        .replace(',', '%2C')}`,
      successMessage: 'Successfully logged in!',
      errorMessage: 'We could not log you in! Please try again!',
    });

    if (response.error) {
      throw response.error;
    }

    return response.data;
  };

  const updateAssessor = async (assessor: Partial<Account>) => {
    const response = await apiHandler({
      method: 'put',
      url: `${serverUri()}/assessor`,
      data: {
        ...assessor,
      },
      successMessage: 'Assessor successfully updated!',
      errorMessage: 'Assessor could not be updated! Please try again!',
    });

    return response.data;
  };

  const updateProvider = async (provider: Partial<Provider>) => {
    const response = await apiHandler({
      method: 'patch',
      url: `${serverUri()}/provider`,
      data: {
        provider,
      },
      successMessage: 'Assessor data successfully updated!',
      errorMessage: 'Assessor data could not be updated! Please try again!',
    });

    return response.data;
  };

  return {
    createAssessor,
    createAssessorByEmail,
    authenticateAssessor,
    authenticateAssessorByEmail,
    getAssessorWithProviderAccount,
    updateAssessor,
    getAuthInfoWallet,
    getAuthInfoEmail,
    updateProvider,
  };
}
