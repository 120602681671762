import { UserContext, UserContextActionTypes } from 'context/UserContext';
import { ApiService } from 'main/api/api.service';
import { AuthService } from 'main/api/auth.service';
import { useContext, useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import './AssessorInfoCheck.css';
import countryList from 'react-select-country-list';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import validator from 'validator';

export const AssessorInfoCheck = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const authService = AuthService();

  const userContext = useContext(UserContext);
  const currentUserState = userContext.state;

  const countries = countryList();
  const countryNames = countries.data.map((e) => e.label);
  const [countryInputValue, setCountryInputValue] = useState<Array<string>>([]);

  const [assessorDataObject, setAssessorDataObject] = useState<{
    businessName: string;
    address: string;
    website: string;
    contactPerson: string;
    email: string;
    country: string;
  }>({
    businessName: '',
    address: '',
    website: '',
    contactPerson: '',
    email: '',
    country: '',
  });

  const [assessorDataFilled, setAssessorDataFilled] = useState<boolean>();
  useEffect(() => {
    if (!currentUserState.account) return;
    const { account } = currentUserState;
    const { walletAddress } = account;

    const { address, businessName, website, contactPerson, email, country } =
      account.provider;

    if (
      !address ||
      !businessName ||
      !website ||
      !contactPerson ||
      !email ||
      !country
    ) {
      fetchUserData(walletAddress);
    } else {
      setAssessorDataFilled(true);
    }
  }, [currentUserState]);

  const fetchUserData = async (walletAddress: string | undefined) => {
    const account = await authService.getAssessorWithProviderAccount(
      walletAddress
    );

    if (!account) {
      return userContext.dispatch({
        type: UserContextActionTypes.LogOut,
      });
    }

    const { address, businessName, website, contactPerson, email, country } =
      account.provider;

    if (
      !address ||
      !businessName ||
      !website ||
      !contactPerson ||
      !email ||
      !country
    ) {
      setAssessorDataObject({
        address: address || '',
        businessName: businessName || '',
        website: website || '',
        contactPerson: contactPerson || '',
        email: email || '',
        country: country || '',
      });
      setCountryInputValue([
        countries.data.find((e) => e.value === country)?.label || '',
      ]);
      setAssessorDataFilled(false);
    } else {
      userContext.dispatch({
        type: UserContextActionTypes.SetAccount,
        value: { ...userContext.state.account, ...account },
      });
      setAssessorDataFilled(true);
    }
  };

  const [actionsDisabled, setActionsDisabled] = useState(false);
  const saveUserData = async () => {
    if (
      assessorDataObject.website &&
      !validator.isURL(assessorDataObject.website)
    ) {
      toast.error('Website is not a valid URL!');
      return false;
    }

    if (
      assessorDataObject.email &&
      !validator.isEmail(assessorDataObject.email)
    ) {
      toast.error('Email is not valid!');
      return false;
    }

    if (
      !assessorDataObject.contactPerson ||
      !assessorDataObject.businessName ||
      !assessorDataObject.website ||
      !assessorDataObject.email ||
      !assessorDataObject.address ||
      !assessorDataObject.country
    ) {
      toast.error(
        'In order to enlist as an assessor you need to fill out the entire form!'
      );
      return false;
    }

    try {
      setActionsDisabled(true);
      const updatedProvider = await authService.updateProvider({
        ...assessorDataObject,
      });
      userContext.dispatch({
        type: UserContextActionTypes.SetAccount,
        value: {
          ...userContext.state.account,
          provider: { ...updatedProvider },
        },
      });
      setAssessorDataFilled(true);
    } catch (e) {
      //do nothing
    } finally {
      setActionsDisabled(false);
    }
  };

  return (
    <>
      {!assessorDataFilled ? (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Modal centered className="mark-spam-modal" show={true}>
            <Modal.Header>
              <Modal.Title>Rodeo Quickstart</Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                maxHeight: '400px',
                overflow: 'scroll',
                overflowX: 'hidden',
              }}
            >
              <h5 className="mb-12">Add your contact information</h5>
              <div className="fs-16 mb-24">
                {' '}
                These details are required to use Rodeo
              </div>
              <div className="mb-16">
                <div className="fs-14 mb-4px">Contact person</div>
                <Form.Control
                  disabled={actionsDisabled}
                  placeholder="Assessor name"
                  value={assessorDataObject.contactPerson}
                  onChange={(e) => {
                    setAssessorDataObject((assessorDataObject) => ({
                      ...assessorDataObject,
                      contactPerson: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="mb-16">
                <div className="fs-14 mb-4px">Business name</div>
                <Form.Control
                  disabled={actionsDisabled}
                  placeholder="Business name"
                  value={assessorDataObject.businessName}
                  onChange={(e) => {
                    setAssessorDataObject((assessorDataObject) => ({
                      ...assessorDataObject,
                      businessName: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="mb-16">
                <div className="fs-14 mb-4px">Website</div>
                <Form.Control
                  disabled={actionsDisabled}
                  placeholder="Website"
                  value={assessorDataObject.website}
                  onChange={(e) => {
                    setAssessorDataObject((assessorDataObject) => ({
                      ...assessorDataObject,
                      website: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="mb-16">
                <div className="fs-14 mb-4px">Email</div>
                <Form.Control
                  disabled={actionsDisabled}
                  placeholder="Email"
                  value={assessorDataObject.email}
                  onChange={(e) => {
                    setAssessorDataObject((assessorDataObject) => ({
                      ...assessorDataObject,
                      email: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="mb-16">
                <div className="fs-14 mb-4px">Address</div>
                <Form.Control
                  disabled={actionsDisabled}
                  placeholder="Address"
                  value={assessorDataObject.address}
                  onChange={(e) => {
                    setAssessorDataObject((assessorDataObject) => ({
                      ...assessorDataObject,
                      address: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="mb-16">
                <div className="fs-14 mb-4px">Country</div>
                <Typeahead
                  disabled={actionsDisabled}
                  id="country"
                  options={countryNames}
                  placeholder="Country"
                  onChange={(country) => {
                    setCountryInputValue(country);
                    setAssessorDataObject((assessorDataObject) => ({
                      ...assessorDataObject,
                      country:
                        countryList().data.find((e) => e.label === country[0])
                          ?.value || '',
                    }));
                  }}
                  selected={countryInputValue}
                ></Typeahead>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="blue-button text-primary-dark py-8 px-12 c-pointer no-text-select"
                  onClick={() => {
                    if (actionsDisabled) return;
                    saveUserData();
                  }}
                >
                  Start
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      ) : (
        children
      )}
    </>
  );
};
