import ComplaintService from 'library/services/ComplaintService';
import {
  ApiService,
  RelatedCidComplaint,
  RelatedComplaintsResponse,
} from 'main/api/api.service';
import { Complaint } from 'models/Complaint';
import { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionContext,
  useAccordionButton,
} from 'react-bootstrap';
import ReactTimeAgo from 'react-time-ago';
import * as icons from 'resources/icons';
import './RelatedComplaints.css';

interface RelatedComplaintsProps {
  complaint: Complaint;
}

const RelatedComplaintGroup = (props: {
  title: string;
  complaints: Complaint[];
}) => {
  const shorten = (str: string) => {
    if (str.length < 15) {
      return str;
    }

    return str.substring(0, 4) + '...' + str.substring(str.length - 4);
  };

  return (
    <div className="mb-16">
      <div className="text-tertiary-light fs-10 lh-12 mb-8">
        RELATED TO {shorten(props.title)}
      </div>
      {props.complaints.map((complaint) => {
        return (
          <RelatedComplaint
            key={`related-complaint-${complaint._id}`}
            complaint={complaint}
          />
        );
      })}
    </div>
  );
};

const RelatedComplaint = (props: { complaint: Complaint }) => {
  const { badgeCssClass, badgeLetter } = ComplaintService.renderStatus(
    props.complaint.status
  );

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center text-primary-light fs-14 lh-20">
        <div
          className={`status-icon status-icon-type-${badgeCssClass} fs-10 lh-12 fw-500 px-4px py-2px mr-8 d-flex justify-content-center align-items-center`}
        >
          {badgeLetter}
        </div>
        #{props.complaint._id}
      </div>
      <div className="text-tertiary-light fs-12 lh-16">
        {props.complaint.created && (
          <ReactTimeAgo
            date={new Date(props.complaint.created)}
            locale="en-US"
          />
        )}
      </div>
    </div>
  );
};

export const RelatedComplaints = (
  props: RelatedComplaintsProps
): JSX.Element => {
  const apiService = ApiService();

  const [relatedComplaints, setRelatedComplaints] =
    useState<RelatedComplaintsResponse>({
      complainant: [],
      cids: [],
    });

  useEffect(() => {
    if (!props.complaint) {
      return;
    }

    if (props.complaint._id) {
      apiService
        .getRelatedComplaints(props.complaint._id)
        .then((related: RelatedComplaintsResponse) => {
          setRelatedComplaints(related);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.complaint]);

  const ContextAwareToggle = (props: { eventKey: string; callback: any }) => {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
      props.eventKey,
      () => props.callback && props.callback(props.eventKey)
    );

    const isCurrentEventKey = activeEventKey === props.eventKey;

    return (
      <div
        onClick={decoratedOnClick}
        className={`related-section-header d-flex align-items-center p-12 c-pointer no-text-select ${
          isCurrentEventKey ? '' : 'related-section-header-bottom-radius'
        }`}
      >
        <div className="related-section-arrow d-flex align-items-center pl-4px pr-16">
          <img
            alt="collapse-icon"
            src={isCurrentEventKey ? icons.downArrowGrey : icons.rightArrowGrey}
          ></img>
        </div>
        <div className="related-section-text text-primary-light fw-700 fs-16 lh-24">
          Related complaints
        </div>
      </div>
    );
  };

  return (
    <Accordion className="related-section" defaultActiveKey="0">
      <ContextAwareToggle eventKey="0" callback={() => {}} />
      <Accordion.Collapse eventKey="0">
        <div className="related-section-content-container p-16 pb-0 accordion-bottom-border-radius d-flex flex-column">
          {relatedComplaints?.complainant.length +
            relatedComplaints.cids.length ===
            0 && (
            <div className="text-secondary-light fs-16 lh-20 pb-16">
              No related complaints.
            </div>
          )}
          <div className="related-section-content d-flex flex-column">
            {relatedComplaints?.complainant.length > 0 && (
              <RelatedComplaintGroup
                title={'COMPLAINANT'}
                complaints={relatedComplaints?.complainant}
              />
            )}
            {relatedComplaints.cids.length > 0 &&
              relatedComplaints.cids.map(
                (relatedCidComplaint: RelatedCidComplaint, index: number) => {
                  return (
                    <RelatedComplaintGroup
                      key={'related-complaints-' + index}
                      title={relatedCidComplaint.infringement}
                      complaints={relatedCidComplaint.complaints}
                    />
                  );
                }
              )}
          </div>
        </div>
      </Accordion.Collapse>
    </Accordion>
  );
};
