import { useNavigate } from 'react-router-dom';
import './ComplaintTicketLog.css';
import { bitscreenUri, lookingGlassUri } from '../../../config';
import { FilterList, Visibility } from 'models/FilterList';

interface ComplaintTicketLogProps {
  recordId: number;
  filterLists: Array<FilterList>;
  resolvedOn: Date | null;
  isSpam: boolean;
}

export const ComplaintTicketLog = (
  props: ComplaintTicketLogProps
): JSX.Element => {
  const { resolvedOn, filterLists, isSpam, recordId } = props;
  const getDate = () => {
    if (resolvedOn) {
      return (
        resolvedOn.getDate() +
        '.' +
        (resolvedOn.getMonth() + 1) +
        '.' +
        resolvedOn.getFullYear()
      );
    }
    return 'N/A';
  };

  const getTime = () => {
    if (!resolvedOn) {
      return 'N/A';
    }

    return resolvedOn.getHours() + ':' + resolvedOn.getMinutes();
  };

  return (
    <div className="ticket-log-section mb-24">
      <div className="ticket-log-section-header fs-16 lh-24 fw-700 p-12 pl-16">
        Ticket log
      </div>
      <div className="ticket-log-section-content p-16">
        <div className="resolved-on-section mb-12">
          {isSpam ? <span>Marked as spam</span> : <span>Resolved</span>} on{' '}
          {getDate()} at {getTime()}.
        </div>
        {!isSpam && !!filterLists.length && (
          <div className="filter-lists-section mb-12">
            CID(s) added to:
            {filterLists.map((e, index) => (
              <div
                className="filter-list-item fs-14 lh-20 fw-500"
                key={`ticket-log-item-${index}`}
              >
                <span
                  onClick={() =>
                    window.open(
                      e.visibility === Visibility.Public
                        ? `${bitscreenUri()}/directory/details/${e.shareId}`
                        : `${bitscreenUri()}/filters/edit/${e.shareId}`,
                      '_blank'
                    )
                  }
                  className="c-pointer"
                >
                  {e.name}
                </span>
              </div>
            ))}
          </div>
        )}
        <div className="published-url-section">
          Record published:
          <div className="published-url">
            <span
              onClick={() =>
                window.open(
                  `${lookingGlassUri()}/records/${recordId}`,
                  '_blank'
                )
              }
              className="c-pointer fs-14 lh-20 fw-500"
            >
              URL
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
