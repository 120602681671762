import { NativeFormControlElement } from '@mui/base/FormControlUnstyled/FormControlUnstyled.types';
import { UserContext } from 'context/UserContext';
import {
  UtilityContext,
  UtilityContextActionTypes,
} from 'context/UtilityContext';
import useLogout from 'library/hooks/useLogout';
import { LoginType } from 'models/Account';
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Dropdown, Form, InputGroup } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import * as icons from 'resources/icons';
import { bitscreenUri, lookingGlassUri } from '../../../config';
import './TopBar.css';

export const TopBar = (): JSX.Element => {
  const userContext = useContext(UserContext);
  const utilityContext = useContext(UtilityContext);
  const generalLogOut = useLogout();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useMemo(() => userContext.state.theme, [userContext]);

  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const handleSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleEnterKeyQueryChange = (
    event: React.KeyboardEvent<NativeFormControlElement>
  ) => {
    if (event.key !== 'Enter') return;
    utilityContext.dispatch({
      type: UtilityContextActionTypes.SetSearchQueryObject,
      value: {
        searchTerm: searchQuery,
        fromAnotherRoute: location.pathname !== '/',
      },
    });
  };

  useEffect(() => {
    if (!utilityContext.state.queryObject.fromAnotherRoute) return;
    utilityContext.dispatch({
      type: UtilityContextActionTypes.SetSearchQueryObject,
      value: {
        searchTerm: searchQuery,
        fromAnotherRoute: false,
      },
    });
    navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [utilityContext.state.queryObject.fromAnotherRoute]);

  useEffect(() => {
    if (location.pathname !== '/') {
      setSearchQuery('');
    }
  }, [location]);

  const shortenString = (string?: string) => {
    if (string) {
      return (
        string.substring(0, 4) + '...' + string.substring(string.length - 4)
      );
    }

    return '';
  };

  const removeQueryStringAndSearch = () => {
    utilityContext.dispatch({
      type: UtilityContextActionTypes.SetSearchQueryObject,
      value: {
        searchTerm: '',
        fromAnotherRoute: false,
      },
    });
    setSearchQuery('');
  };

  return (
    <>
      <div
        className={`top-bar d-flex top-bar-${theme} align-items-center pt-12 pb-20 px-32`}
      >
        <div
          className="d-flex top-bar-logo fw-700 fs-20 lh-38 text-primary-dark"
          onClick={() => {
            removeQueryStringAndSearch();
            navigate('/');
          }}
        >
          <span className="c-pointer no-text-select">Rodeo</span>
        </div>
        {location.pathname !== '/login' && (
          <div className="d-flex top-bar-search justify-content-center">
            <InputGroup
              className={`search-input-group search-${theme} no-outline`}
            >
              <InputGroup.Text
                className="search-icon-container pt-8 pb-8 pl-12 pr-10"
                id="search"
              >
                <img alt="search-icon" src={icons.search} />
              </InputGroup.Text>
              <Form.Control
                className="search-input pl-0 fs-14 lh-20 fw-400 text-tertiary-dark no-outline no-box-shadow"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search"
                value={searchQuery || ''}
                onChange={handleSearchQueryChange}
                onKeyPress={handleEnterKeyQueryChange}
              />
              <InputGroup.Text
                className="remove-icon-container pt-8 pb-8 pl-12 pr-10"
                id="search"
              >
                {searchQuery && (
                  <img
                    alt="remove-icon"
                    onClick={removeQueryStringAndSearch}
                    className="remove-icon c-pointer no-text-select"
                    src={icons.removeList}
                  />
                )}
              </InputGroup.Text>
            </InputGroup>
          </div>
        )}
        {location.pathname !== '/login' && (
          <div className="d-flex top-bar-actions align-items-center justify-content-end">
            <div className="d-flex actions-button pr-22">
              <Dropdown align={'end'} className="top-bar-menu-dropdown">
                <Dropdown.Toggle className="top-bar-menu-dropdown-button d-flex align-items-center justify-content-center c-pointer no-text-select">
                  <img
                    alt="menu-icon"
                    className="c-pointer"
                    src={icons.menu}
                  ></img>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    disabled={true}
                    className="top-bar-menu-dropdown-title-item fs-10 lh-12"
                    eventKey="1"
                  >
                    APP SWITCHER
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => window.open(bitscreenUri())}>
                    BitScreen
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => window.open(lookingGlassUri())}>
                    Looking Glass
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {!userContext.state.account && (
              <Button className="mr-8" onClick={() => navigate('/login')}>
                Log in
              </Button>
            )}
            {userContext.state.account && (
              <Dropdown className="top-bar-user-actions-dropdown">
                <Dropdown.Toggle className="d-flex user-pill c-pointer no-text-select px-12 py-8 fs-12 lh-18">
                  {shortenString(
                    userContext.state.loginType === LoginType.Wallet
                      ? userContext.state.account.walletAddress
                      : userContext.state.account.loginEmail
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    className="user-pill-actions-big d-flex"
                    eventKey="1"
                    onClick={() => navigate('/settings')}
                  >
                    <img
                      alt="settings-icon"
                      className="mr-10"
                      src={icons.settings}
                    />
                    Settings
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="user-pill-actions-big d-flex"
                    eventKey="1"
                    onClick={() =>
                      generalLogOut(userContext.state.loginType as LoginType)
                    }
                  >
                    <img
                      alt="sign-out-icon"
                      className="mr-10"
                      src={icons.signOut}
                    />
                    Sign out
                  </Dropdown.Item>
                  <Dropdown.Divider></Dropdown.Divider>
                  <Dropdown.Item
                    className="user-pill-actions-small d-flex c-pointer no-text-select fs-12 lh-16"
                    eventKey="1"
                  >
                    Privacy
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="user-pill-actions-small c-pointer no-text-select fs-12 lh-16"
                    eventKey="1"
                  >
                    Terms
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="user-pill-actions-small c-pointer no-text-select fs-12 lh-16"
                    eventKey="1"
                  >
                    Murmuration Labs
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        )}
      </div>
    </>
  );
};
