import { Complaint } from 'models/Complaint';
import { NetworkChips } from 'modules/Common/NetworkChips';
import { useEffect, useState } from 'react';
import { Tooltip } from 'react-bootstrap';
import ReactTimeAgo from 'react-time-ago';
import './ComplaintDescription.css';

interface ComplaintDescriptionProps {
  complaint: Complaint;
  redactedAreas: Array<{
    rangeStart: number;
    rangeEnd: number;
  }>;
  selectedArea: string;
  setSelectedArea: React.Dispatch<React.SetStateAction<string>>;
  canInteract: boolean;
}

export const ComplaintDescription = (
  props: ComplaintDescriptionProps
): JSX.Element => {
  const [showMore, setShowMore] = useState(false);
  const {
    selectedArea,
    setSelectedArea,
    complaint,
    redactedAreas,
    canInteract,
  } = props;

  useEffect(() => {
    clear();
  }, [showMore]);

  const highlightOptions = (e: any) => {
    const selection = document.getSelection();
    if (!selection || selection.rangeCount === 0) return clear();
    const range = selection.getRangeAt(0);
    if (
      selection.rangeCount === 0 ||
      range.collapsed ||
      !range.toString().trim()
    ) {
      return clear();
    }

    setTimeout(() => {
      const selection = document.getSelection();
      if (!selection || selection.rangeCount === 0) return clear();
    });
    setSelectedArea(document.getSelection()?.getRangeAt(0).toString() || '');
  };

  function clear() {
    document.getSelection()?.removeAllRanges();
    const e = document.getElementById('redact-text-container');
    if (e && e.parentElement) {
      e.parentElement.removeChild(e);
    }
    setSelectedArea('');
  }

  const getDescription = () => {
    const { complaintDescription } = complaint;

    let descriptionDivInnerHtmlMap: Array<{
      start: number;
      end: number;
      redacted: boolean;
    }> = [];

    if (!redactedAreas.length) {
      descriptionDivInnerHtmlMap.push({
        start: 0,
        end: complaintDescription.length,
        redacted: false,
      });
    } else {
      for (let i = 0; i < redactedAreas.length; i++) {
        if (i === 0) {
          if (redactedAreas[i].rangeStart > 0) {
            descriptionDivInnerHtmlMap.push({
              start: 0,
              end: redactedAreas[i].rangeStart,
              redacted: false,
            });
          }
          descriptionDivInnerHtmlMap.push({
            start: redactedAreas[i].rangeStart,
            end: redactedAreas[i].rangeEnd,
            redacted: true,
          });

          if (
            redactedAreas.length === 1 &&
            redactedAreas[0].rangeEnd < complaintDescription.length
          ) {
            descriptionDivInnerHtmlMap.push({
              start: redactedAreas[0].rangeEnd,
              end: complaintDescription.length,
              redacted: false,
            });
          }
        } else if (i === redactedAreas.length - 1) {
          if (
            redactedAreas.length > 1 &&
            redactedAreas[i - 1].rangeEnd < redactedAreas[i].rangeStart
          ) {
            descriptionDivInnerHtmlMap.push({
              start: redactedAreas[i - 1].rangeEnd,
              end: redactedAreas[i].rangeStart,
              redacted: false,
            });
          }
          descriptionDivInnerHtmlMap.push({
            start: redactedAreas[i].rangeStart,
            end: redactedAreas[i].rangeEnd,
            redacted: true,
          });
          if (redactedAreas[i].rangeEnd < complaintDescription.length) {
            descriptionDivInnerHtmlMap.push({
              start: redactedAreas[redactedAreas.length - 1].rangeEnd,
              end: complaintDescription.length,
              redacted: false,
            });
          }
        } else {
          if (redactedAreas[i - 1].rangeEnd < redactedAreas[i].rangeStart) {
            descriptionDivInnerHtmlMap.push({
              start: redactedAreas[i - 1].rangeEnd,
              end: redactedAreas[i].rangeStart,
              redacted: false,
            });
          }
          descriptionDivInnerHtmlMap.push({
            start: redactedAreas[i].rangeStart,
            end: redactedAreas[i].rangeEnd,
            redacted: true,
          });
        }
      }
    }

    if (complaintDescription.length > 100 && !showMore) {
      descriptionDivInnerHtmlMap = descriptionDivInnerHtmlMap
        .filter((e) => {
          return e.start <= 100;
        })
        .map((e) => {
          if (e.end > 100) e.end = 100;
          return e;
        });
    }

    return (
      <div>
        {descriptionDivInnerHtmlMap.map((e, i) => {
          return (
            <span
              key={`description-text-substring-${i}`}
              className={`${e.redacted ? 'redacted-text' : ''}`}
            >
              {complaintDescription.substring(e.start, e.end)}
            </span>
          );
        })}
      </div>
    );
  };

  const getCreatedAt = () => {
    if (complaint.created) {
      return new Date(complaint.created);
    }

    return new Date();
  };

  const renderTooltip = (props: any) => (
    <Tooltip className="redaction-tooltip" {...props}>
      You can redact parts of the description by highlighting the text and using
      the ‘Redact content’ button. These parts will appear redacted on the
      Looking Glass individual record page after the assessement has been
      published
    </Tooltip>
  );

  return (
    <>
      <div
        onMouseUp={highlightOptions}
        onMouseDown={clear}
        className="description-section mb-24"
      >
        <div className="description-section-header py-12 px-16 d-flex justify-content-between">
          <div className="d-flex align-items-center text-primary-light fs-16 lh-24 fw-700">
            <span className="mr-10">Original complaint</span>{' '}
            <NetworkChips networks={complaint.networks} />
          </div>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center text-secondary-light fs-14 lh-20">
              <ReactTimeAgo date={getCreatedAt()} locale="en-US" />
            </div>
          </div>
        </div>
        <div className="description-section-content p-16">
          <div className="mb-16 text-primary-light fs-16 lh-24 fw-700 ">
            {complaint.title}
          </div>
          <div className="text-primary-light fs-14 lh-20 word-wrap mb-16 override-no-text-select">
            {getDescription()}
            {!showMore && complaint.complaintDescription.length > 100 && (
              <span className="no-text-select">...</span>
            )}
          </div>
          {complaint.complaintDescription.length > 100 && (
            <div
              className="show-more-button c-pointer no-text-select fw-500 fs-14 lh-20"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? 'Show less' : 'Show more'}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
