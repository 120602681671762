import React, { useState } from 'react';
import Link from '@mui/material/Link';

import { Button, Form, Modal } from 'react-bootstrap';

export interface ConsentModalProps {
  show: boolean;
  closeCallback: (consentValue: boolean) => void;
}

export default function ConsentModal(props: ConsentModalProps): JSX.Element {
  const [consentValue, setConsentValue] = useState<boolean>(false);

  const handleCheckboxChange = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setConsentValue(event.target.checked);
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.closeCallback(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Welcome to Rodeo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          By using Rodeo, you agree to our{' '}
          <Link
            href="https://github.com/Murmuration-Labs/bitscreen/blob/master/terms_of_service.md"
            target="_blank"
          >
            Terms of Service
          </Link>{' '}
          &{' '}
          <Link
            href="https://github.com/Murmuration-Labs/bitscreen/blob/master/privacy_policy.md"
            target="_blank"
          >
            Privacy Policy
          </Link>
          . If you do not agree, you cannot use Rodeo.
        </p>
        <Form.Group className="mb-3" controlId="consentCheckbox">
          <Form.Check
            onChange={handleCheckboxChange}
            type="checkbox"
            label="I have read and agree."
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            props.closeCallback(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={!consentValue}
          onClick={() => {
            props.closeCallback(true);
          }}
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
