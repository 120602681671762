import { Provider } from './Account';
import { ProviderFilter } from './ProviderFilter';

export enum Visibility {
  None,
  Private,
  Public,
  Shared,
  Exception,
}

export interface FilterList {
  id: number;
  name: string;
  cidsCount?: number;
  visibility: Visibility;
  enabled: boolean;
  shareId: string;
  shared?: boolean;
  isBulkSelected?: boolean;
  description?: string;
  providerId: number;
  provider?: Provider;
  provider_Filters?: ProviderFilter[];
  notes?: string;
  created?: string;
  updated?: string;
}
